import React, { Component } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from 'common/history';
import { navigateTo } from 'store/actions';
import { getProposalKey, getPreviewPageName } from 'common/api.mock';
import { PageView } from 'components/PageView';
import { Container } from 'components/Container';
import ResetHomeAddress from 'pages/shared/ResetHomeAddress';
import { getLocalStorageItem } from 'common/localStorage';
import Start from '../../shared/Start';
import Calculate12 from '../../shared/Calculate12';
import Calculate2 from '../../shared/Calculate2';
import Calculate3 from '../../shared/Calculate3';
import Result1 from '../../shared/Result1';
import Result2SolarPowerProsVariation from '../../shared/Result2SolarPowerProsVariation';
import Result3 from '../../shared/Result3';
import Qualify2 from '../../shared/Qualify2';
import Qualify8 from '../../shared/Qualify8';
import Qualify9 from '../../shared/Qualify9';
import Meeting1 from '../../shared/Meeting1';
import Meeting2 from '../../shared/Meeting2';
import Meeting3 from '../../shared/Meeting3';
import MeetingCalendly from '../../shared/MeetingCalendly';
import Bills1 from '../../shared/Bills1';
import Bills2 from '../../shared/Bills2';
import Thankyou from '../../shared/Thankyou';
import Calculate21 from '../../shared/Calculate21';
import Commercial2 from '../../shared/Commercial2';
import Commercial3 from '../../shared/Commercial3';
import GetNotified1 from '../../shared/GetNotified1';
import GetNotified2 from '../../shared/GetNotified2';
import UploadBills from '../../shared/UploadBills';
import Qualify82 from '../../shared/Qualify82';
import QualifyPage from '../../shared/QualifyPage';
import InactiveCompany from '../../shared/InactiveCompany';
import DisqualifyPage from '../../shared/DisqualifyPage';
import Proposals from '../../shared/Proposals';
import QualifyStart from '../../shared/QualifyStart';
import Retry from '../../shared/Retry';
import PhoneVerify from '../../shared/PhoneVerify';

class SolarPowerProsVariation extends Component {
  constructor(props) {
    super(props);
    const { theme, user, actions } = props;
    const previewPage = getPreviewPageName() ? `/${getPreviewPageName()}` : '';
    if (getProposalKey()) {
      actions.navigateTo('/proposals', false);
    } else if (theme && theme.status && user) {
      if (previewPage) {
        actions.navigateTo(previewPage);
      } else if (
        (user.variation || 'current') !== getLocalStorageItem('variation')
      ) {
        actions.navigateTo('/start');
      } else {
        actions.navigateTo(user.step || '/start');
      }
    } else {
      actions.navigateTo('/inactive-company', false);
    }
  }

  render() {
    return (
      <Container>
        <Router history={history}>
          <PageView />
          <Switch>
            <Route exact path="/start" component={Start} />
            <Route exact path="/getnotified1" component={GetNotified1} />
            <Route exact path="/getnotified2" component={GetNotified2} />
            <Route exact path="/calculate12" component={Calculate12} />
            <Route exact path="/calculate2" component={Calculate2} />
            <Route exact path="/calculate21" component={Calculate21} />
            <Route exact path="/commercial2" component={Commercial2} />
            <Route exact path="/commercial3" component={Commercial3} />
            <Route exact path="/calculate3" component={Calculate3} />
            <Route exact path="/result1" component={Result1} />
            <Route
              exact
              path="/result2"
              component={Result2SolarPowerProsVariation}
            />
            <Route exact path="/result3" component={Result3} />
            <Route exact path="/qualify/start" component={QualifyStart} />
            <Route exact path="/qualify/default1" component={Qualify2} />
            <Route exact path="/qualify/default2" component={Qualify8} />
            <Route exact path="/qualify/default2/retry" component={Qualify82} />
            <Route exact path="/qualify/end" component={Qualify9} />
            <Route exact path="/qualify/:id" component={QualifyPage} />
            <Route exact path="/disqualify" component={DisqualifyPage} />
            <Route exact path="/meeting1" component={Meeting1} />
            <Route exact path="/meeting2" component={Meeting2} />
            <Route exact path="/meeting3" component={Meeting3} />
            <Route exact path="/meetingcalendly" component={MeetingCalendly} />
            <Route exact path="/bills1" component={Bills1} />
            <Route exact path="/bills2" component={Bills2} />
            <Route exact path="/upload-bills" component={UploadBills} />
            <Route exact path="/thankyou" component={Thankyou} />
            <Route exact path="/inactive-company" component={InactiveCompany} />
            <Route exact path="/proposals" component={Proposals} />
            <Route exact path="/retry" component={Retry} />
            <Route exact path="/phoneverify" component={PhoneVerify} />
            <Route exact path="/resethome" component={ResetHomeAddress} />
            <Redirect to="/start" />
          </Switch>
        </Router>
      </Container>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      navigateTo,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolarPowerProsVariation);
