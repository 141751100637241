import React from 'react';
import DefaultLayout from 'components/DefaultLayout';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

const Retry = () => {
  const [t] = useTranslation('translation');
  return (
    <DefaultLayout>
      <div className="content calculate-2-page">
        <div className="form">
          <div className="form-content">
            <div className="mb-20 w100">
              <ProgressBar value={50} step={2} />
            </div>
            <Chat>
              <div>
                <Trans
                  t={t}
                  i18nKey="There is something wrong while estimation. Please try again. If so, please click <1>here.</1>"
                >
                  There is something wrong while estimation. Please try again.
                  If so, please click&nbsp;
                  <Link to="/calculate3" className="link-text">
                    here.
                  </Link>
                </Trans>
                <br />
                <br />
                <Trans
                  t={t}
                  i18nKey="If you see the screen again, please click <1>here.</1>"
                >
                  If you see the screen again, please click&nbsp;
                  <Link to="/inactive-company" className="link-text">
                    here.
                  </Link>
                </Trans>
              </div>
            </Chat>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Retry;
