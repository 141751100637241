import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import Chat from 'components/Chat';
import ProgressBar from 'components/ProgressBar';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import { confirmVerificationCode, sendVerificationCode } from 'common/api.mock';
import useDigitInput from 'react-digit-input';
import { Link } from '@material-ui/core';
import StyledDigitInput from 'components/Forms/DigitInput/styled';
import { useTranslation } from 'react-i18next';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import {
  setUserData,
  saveSession,
  trackFacebookPixel,
  navigateTo,
  trackForwardProgress,
} from '../../../store/actions';
import { Result2 as StyledResult2, InputWrapper } from './styled';

const formSchema = Yup.object().shape({});

function PhoneVerify(props) {
  const variation = VARIATION_OPTIONS[getLocalStorageItem('variation')];
  const [state, setState] = React.useState({
    loading: false,
    loaded: false,
    error: false,
  });

  const { loading, error } = state;

  const [firstDigit, setFirstDigit] = React.useState(null);
  const [t] = useTranslation('translation');
  React.useEffect(() => {
    if (firstDigit) {
      firstDigit.focus();
    }
  }, [firstDigit]);

  const [code, setCode] = React.useState('');

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value: code,
    onChange: setCode,
  });

  digits[0].ref = (input) => {
    setFirstDigit(input);
  };

  const handleBack = () => {
    const { actions } = props;
    actions.navigateTo('/result2');
  };

  const handleNext = async () => {
    const { actions, user } = props;
    setState({ ...state, loading: true, loaded: true });

    const result = await confirmVerificationCode({
      phone: user.phone,
      code,
    });
    if (result) {
      actions.setUserData({
        ...user,
        step: '/result3',
        is_phone_verified: true,
      });
      actions.trackForwardProgress();
      await actions.saveSession();

      setState({ ...state, loading: false, loaded: true });
      actions.navigateTo('/result3');
    } else {
      setState({ ...state, loading: false, loaded: true, error: true });
    }
  };

  const handleSendCode = async (e) => {
    const { user } = props;
    e.preventDefault();
    setState({ ...state, loading: true, loaded: true });
    try {
      setCode('');
      await sendVerificationCode({ phone: user.phone });
      setState({ ...state, loading: false, loaded: true, error: false });
    } catch (exception) {
      setState({ ...state, loading: false, loaded: true, error: true });
    }
  };

  return (
    <>
      <variation.header />
      <div className="main-container" style={{ overflow: 'auto' }}>
        <div className="main">
          <StyledResult2 className="content result-2-page">
            <Formik
              initialValues={{}}
              validationSchema={formSchema}
              onSubmit={handleNext}
              validateOnMount
            >
              <Form className="form">
                <div className="form-content">
                  <div className="mb-20 w100">
                    <ProgressBar value={100} step={4} />
                  </div>
                  <Chat
                    mode="loading" //
                    loading={loading}
                  >
                    <div className="mb-16">
                      {!error ? (
                        <span>
                          {t(
                            'Type the four digit verification code sent to your phone.'
                          )}
                        </span>
                      ) : (
                        <span style={{ color: 'red' }}>
                          {t(
                            'That verification code was incorrect, please try again.'
                          )}
                        </span>
                      )}
                    </div>
                    <InputWrapper className="input-wrapper">
                      <StyledDigitInput
                        className="first-digit"
                        inputMode="decimal"
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        pattern="\d*"
                        ref={firstDigit}
                        {...digits[0]}
                      />
                      <StyledDigitInput
                        inputMode="decimal"
                        pattern="\d*"
                        {...digits[1]}
                      />
                      <StyledDigitInput
                        inputMode="decimal"
                        pattern="\d*"
                        {...digits[2]}
                      />
                      <StyledDigitInput
                        inputMode="decimal"
                        pattern="\d*"
                        {...digits[3]}
                      />
                    </InputWrapper>
                    <Link
                      href="#none"
                      role="button"
                      className="resend-btn"
                      onClick={handleSendCode}
                    >
                      {t('Resend Code')}
                    </Link>
                  </Chat>
                </div>
                <div className="form-actions">
                  <Button outline onClick={handleBack}>
                    <BackIcon />
                  </Button>
                  <Button
                    type="submit" //
                    full
                    disabled={code.trim().length < 4}
                  >
                    {t('Unlock Estimate')}
                    <ArrowRightIcon />
                  </Button>
                </div>
              </Form>
            </Formik>
          </StyledResult2>
          <variation.footer showLegal />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerify);
