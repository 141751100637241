import React from 'react';
import { ReactComponent as ChargerIcon } from 'assets/images/charger.svg';
import StyledWrapper from './styled';

const Charger = ({ title, description, cost, selected, onClick }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StyledWrapper className={`charger-option ${selected ? 'selected' : ''}`}>
        <div className="charger-option-content">
          <ChargerIcon />
          {!!title && <div className="option-header">{title}</div>}
          <div className="option-description">{description}</div>
          <div className={title ? 'option-rate-title' : 'option-rate'}>
            <span>+${cost}/</span>mo.
          </div>
        </div>
        <div
          className={`add-option ${selected ? 'selected' : ''}`}
          onClick={onClick}
        >
          {selected ? 'Remove' : 'Add'}
        </div>
      </StyledWrapper>
    </div>
  );
};

export default Charger;
