import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chat from 'components/Chat';
import Button from 'components/Button';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { scrollToTop } from 'common/utils';
import { withTranslation } from 'react-i18next';
import StyledQualifyStart from './styled';

class QualifyStart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleNext = async () => {
    const { actions } = this.props;
    actions.setUserData({ step: '/qualify/default1' });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/qualify/default1');
  };

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/result3');
  };

  handleShow = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  render() {
    const { step } = this.state;
    const { saving, t } = this.props;

    return (
      <DefaultLayout>
        <StyledQualifyStart className="content qualify-start-page custom-stepper">
          <div className="form">
            <div className="material-progress-blank" />
            <div className="form-content">
              <Chat onShow={this.handleShow}>
                {t(
                  'I just have a few quick questions to help us expedite your quote! This should take 60 seconds or less.'
                )}
              </Chat>
            </div>
            {step > 0 && (
              <div className="form-actions">
                <Button
                  outline
                  borderless
                  full
                  left
                  onClick={this.handleBack}
                  className="pl-0 pr-0"
                >
                  <BackIcon />
                  {t('Back')}
                </Button>
                <Button full onClick={this.handleNext} disabled={saving}>
                  {t('Next')}
                  <ArrowRightIcon />
                </Button>
              </div>
            )}
          </div>
        </StyledQualifyStart>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = (store) => ({
  saving: store.user.saving,
  user: store.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(QualifyStart)
);
