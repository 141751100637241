export const CLIENT_RATINGS = [
  { label: 'Excellent', star: 5 },
  { label: 'Good', star: 4 },
  { label: 'Normal', star: 2 },
  { label: 'Not Bad', star: 1 },
  { label: 'Bad', star: 0 },
];

export const BUFFER_TIME = 0;

export const GAP_TIME = 30;
