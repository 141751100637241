import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import MaterialStepper from 'components/MaterialStepper';
import TextInput from 'components/Forms/TextInput';
import Chat from 'components/Chat';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import { getAddresses } from 'common/api.mock';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { scrollToTop, getQuestionsCount } from 'common/utils';
import DefaultLayout from 'components/DefaultLayout';
import { TextField } from '@material-ui/core';
import { useOutlinedTextFieldStyles } from 'components/Forms/TextInput/styled';
import { withTranslation } from 'react-i18next';
import StyledQualify2 from './styled';

const formSchema = Yup.object().shape({
  address: Yup.string().required('Required'),
  address2: Yup.string(),
});

class Qualify2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions_count: 0,
    };
  }

  componentDidMount() {
    scrollToTop();
    this.initData();
  }

  initData() {
    const { theme } = this.props;
    this.setState({
      questions_count: getQuestionsCount(theme),
    });
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/qualify/start');
  };

  handleNext = async (values) => {
    const { actions } = this.props;
    const [street = '', city = '', state = '', zipcode = ''] =
      values.address.split(',');
    if (!street || !city || !state || !zipcode) {
      return;
    }
    const step = '/qualify/1';
    actions.setUserData({
      address: street.trim(),
      city: city.trim(),
      state: state.trim(),
      zip_code: zipcode.trim(),
      address2: values.address2,
      step,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  loadAddress = async (value) => {
    try {
      const { data } = await getAddresses(value);
      return data;
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  render() {
    const { t, user, theme } = this.props;
    const { questions_count } = this.state;
    const address =
      user.address && user.city && user.state
        ? [user.address, user.city, user.state, user.zip_code]
            .filter((item) => !!item)
            .join(', ')
        : '';
    const step = 1;

    return (
      <DefaultLayout>
        <StyledQualify2 className="content qualify-2-page custom-stepper">
          <Formik
            initialValues={{ address, address2: user.address2 || '' }}
            validationSchema={formSchema}
            onSubmit={this.handleNext}
            validateOnMount
          >
            {({ isValid, isSubmitting }) => {
              return (
                <Form className="form">
                  <div className="material-progress">
                    <MaterialStepper
                      activeStep={1}
                      variation={user.variation}
                    />
                  </div>
                  <div className="form-content">
                    <Chat>
                      <div className="question-number">
                        {t('QUESTION {{step}} OF {{questions_count}}', {
                          step,
                          questions_count,
                        })}
                      </div>
                      <div className="mb-16">
                        {t('Confirm property location.')}
                      </div>
                      <div className="mb-16">
                        <Field name="address">
                          {({ field }) => {
                            const classes = useOutlinedTextFieldStyles({
                              theme,
                            });
                            return (
                              <TextField
                                variant="outlined"
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                name={field.name}
                                label={t('Address')}
                                placeholder=""
                                className={classes.outlinedTextField}
                                error={!field.value}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="mb-8">
                        <Field
                          name="address2"
                          placeholder={t('Apartment/Unit number')}
                          theme={theme}
                          component={TextInput}
                          autoFocus
                        />
                      </div>
                    </Chat>
                  </div>
                  <div className="form-actions">
                    <Button
                      outline
                      borderless
                      full
                      left
                      onClick={this.handleBack}
                      className="pl-0 pr-0"
                    >
                      <BackIcon />
                      {t('Back')}
                    </Button>
                    <Button
                      type="submit"
                      full
                      disabled={!isValid || isSubmitting}
                    >
                      {t('Next')}
                      <ArrowRightIcon />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </StyledQualify2>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Qualify2)
);
