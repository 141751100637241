/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import FileUpload from 'components/Forms/FileUpload';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as CloudUploadIcon } from 'assets/images/icon-cloud-upload.svg';
import { getLastMonths } from 'common/utils';
import {
  setUserData,
  saveSession,
  uploadBills,
  navigateTo,
  sendUtilityBillEmail,
  trackForwardProgress,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import * as APIMock from 'common/api.mock';
import StyledUploadBills from './styled';

const formSchema = Yup.object().shape({
  file1: Yup.mixed().required(),
  file2: Yup.mixed(),
  file3: Yup.mixed(),
  file4: Yup.mixed(),
});

class UploadBills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        file1: {
          status: null, // null, selected, uploading, success, error
          errorMessage: null,
        },
        file2: {
          status: null,
          errorMessage: null,
        },
        file3: {
          status: null,
          errorMessage: null,
        },
        file4: {
          status: null,
          errorMessage: null,
        },
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isUploaded() && prevState !== this.state) {
      this.next();
    }
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleSubmit = async (values) => {
    const months = getLastMonths();
    months.forEach((month) => {
      const { data } = this.state;
      if (values[month.name] && data[month.name].status === 'selected') {
        const formData = new FormData();
        formData.append(
          'file',
          values[month.name],
          `${month.name}-${month.label.toLowerCase()}.${values[month.name].name
            .split('.')
            .pop()}`
        );
        formData.append('label', month.label);

        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            [month.name]: {
              status: 'uploading',
              errorMessage: null,
            },
          },
        }));

        APIMock.uploadFiles(formData)
          .then(() => {
            this.setState((prevState) => ({
              data: {
                ...prevState.data,
                [month.name]: {
                  status: 'success',
                  errorMessage: null,
                },
              },
            }));
          })
          .catch((err) => {
            this.setState((prevState) => ({
              data: {
                ...prevState.data,
                [month.name]: {
                  status: 'error',
                  errorMessage: err.message,
                },
              },
            }));
          });
      }
    });
  };

  handleSelectFile = (fieldName, form, event) => {
    const { data } = this.state;
    const MAX_FILE_SIZE = 10;
    const file = event.currentTarget.files[0];
    form.setFieldValue(fieldName, file);

    if (file) {
      const fileSize = Math.round(file.size / 1024);
      if (fileSize >= MAX_FILE_SIZE * 1024) {
        this.setState({
          data: {
            ...data,
            [fieldName]: {
              status: 'error',
              errorMessage: `File too large! Should be less than ${MAX_FILE_SIZE}mb.`,
            },
          },
        });
      } else {
        this.setState({
          data: {
            ...data,
            [fieldName]: {
              status: 'selected',
              errorMessage: null,
            },
          },
        });
      }
    } else {
      this.setState({
        data: {
          ...data,
          [fieldName]: {
            status: null,
            errorMessage: null,
          },
        },
      });
    }
  };

  canSubmit = () => {
    const { data } = this.state;
    if (data.file1.status !== 'selected' && data.file1.status !== 'success') {
      return false;
    }

    if (
      data.file2.status !== 'selected' &&
      data.file2.status !== 'success' &&
      data.file2.status !== null
    ) {
      return false;
    }

    if (
      data.file3.status !== 'selected' &&
      data.file2.status !== 'success' &&
      data.file3.status !== null
    ) {
      return false;
    }

    if (
      data.file4.status !== 'selected' &&
      data.file2.status !== 'success' &&
      data.file4.status !== null
    ) {
      return false;
    }

    let fileCount = 0;
    const months = getLastMonths();
    months.forEach((month) => {
      if (data[month.name].status === 'selected') {
        fileCount++;
      }
    });
    if (fileCount === 0) {
      return false;
    }

    return true;
  };

  isUploaded = () => {
    const { data } = this.state;

    if (data.file1.status !== 'success') {
      return false;
    }

    if (data.file2.status !== 'success' && data.file2.status !== null) {
      return false;
    }

    if (data.file3.status !== 'success' && data.file3.status !== null) {
      return false;
    }

    if (data.file4.status !== 'success' && data.file4.status !== null) {
      return false;
    }

    return true;
  };

  next = async () => {
    const { actions, user } = this.props;
    actions.setUserData({
      step: user.is_instant_estimate ? '/result3' : '/start',
    });
    await actions.sendUtilityBillEmail();
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/thankyou', false);
  };

  render() {
    const { saving, t } = this.props;
    const { data } = this.state;

    return (
      <DefaultLayout showFooter={false}>
        <StyledUploadBills className="content upload-bills-page custom-stepper">
          <Formik
            initialValues={{
              file1: null,
              file2: null,
              file3: null,
              file4: null,
            }}
            validationSchema={formSchema}
            validateOnMount
            onSubmit={this.handleSubmit}
          >
            {({ isValid, isSubmitting }) => {
              return (
                <Form className="form">
                  <div className="material-progress-blank" />
                  <div className="form-content text-center">
                    <div className="form-title mb-16">
                      {t('Please upload a copy of your last electric bill')}
                    </div>
                    <div className="form-subtext mb-16">
                      {t(
                        "If your bill does not show more than one month's usage, please upload at least two bills."
                      )}
                    </div>
                    <div className="mb-16">
                      {getLastMonths().map((month, index) => (
                        <div
                          key={month.name}
                          className={index !== 3 ? 'mb-16' : ''}
                        >
                          <Field
                            name={month.name}
                            label={t('{{month}} bill', {
                              month: t(month.label),
                            })}
                            component={FileUpload}
                            optional={index > 0}
                            status={data[month.name].status}
                            errorMessage={data[month.name].errorMessage}
                            fileSelected={this.handleSelectFile}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="form-actions form-footer">
                    <Button outline onClick={this.handleBack}>
                      <BackIcon />
                    </Button>
                    <Button
                      type="submit"
                      full
                      disabled={
                        !isValid || isSubmitting || saving || !this.canSubmit()
                      }
                    >
                      {t('Upload')}
                      <CloudUploadIcon />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </StyledUploadBills>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data, saving } }) => ({
  user: data,
  saving,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      uploadBills,
      navigateTo,
      sendUtilityBillEmail,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(UploadBills)
);
