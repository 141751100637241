import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { verifyZipCode } from 'common/api.mock';
import DefaultLayout from 'components/DefaultLayout';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import * as Yup from 'yup';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import TextInput from 'components/Forms/TextInput';
import StyledValidateZipCode from './styled';

const formSchema = Yup.object().shape({
  zip_code: Yup.string().required('Required'),
});

// ValidateZipCode is not good name
// input zip code manually when there is not zipcode for the address.
class ValidateZipCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/start');
  };

  handleNext = async (values) => {
    const { zip_code } = values;
    const { actions } = this.props;

    let step = '/calculate12';
    if (zip_code) {
      const { status, is_coverage_area } = await verifyZipCode(zip_code);
      step = status ? '/calculate12' : '/getnotified1';
      if (status && is_coverage_area) step = '/calculate2';
    }
    actions.setUserData({
      ...values,
      step,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    actions.navigateTo(step);
  };

  render() {
    const { show } = this.state;
    const { user, theme, t } = this.props;

    return (
      <DefaultLayout>
        <StyledValidateZipCode className="content result-2-page">
          <Formik
            initialValues={{
              zip_code: user.zip_code || '',
            }}
            validationSchema={formSchema}
            onSubmit={this.handleNext}
            validateOnMount
          >
            {({ isValid, isSubmitting }) => {
              return (
                <Form className="form">
                  <div className="form-content">
                    <div className="mb-20 w100">
                      <ProgressBar value={25} step={1} />
                    </div>
                    <Chat onShow={() => this.setState({ show: true })}>
                      <div className="mb-16">{t("What's your zipcode?")}</div>
                      {show && (
                        <div className="mb-16">
                          <Field
                            name="zip_code"
                            type="text"
                            placeholder={t('Postal Code')}
                            theme={theme}
                            component={TextInput}
                            autoFocus
                          />
                        </div>
                      )}
                    </Chat>
                  </div>
                  <div className="form-actions">
                    <Button outline onClick={this.handleBack}>
                      <BackIcon />
                    </Button>
                    <Button
                      type="submit"
                      full
                      disabled={!isValid || isSubmitting}
                    >
                      {t('Continue')}
                      <ArrowRightIcon />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </StyledValidateZipCode>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui: { theme } }) => ({
  user: data,
  saving,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(ValidateZipCode)
);
