import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultLayout from 'components/DefaultLayout';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import SlickSlider from 'components/SlickSlider';
import SliderCard from 'components/SliderCard';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { formatNumber } from 'common/utils';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import StyledResult1Variation3 from './styled';

class Result1Variation3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      show: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ step: 2 });
    }, 2000);
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/calculate2');
  };

  handleNext = async () => {
    const { actions } = this.props;
    actions.setUserData({ step: '/result2' });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/result2');
  };

  render() {
    const { user: data, saving, theme, t } = this.props;
    const { step, show } = this.state;

    return (
      <DefaultLayout>
        <StyledResult1Variation3 className="content result-1-page">
          <div className="form">
            <div className="form-content">
              <div className="mb-20 w100">
                <ProgressBar value={75} step={3} />
              </div>
              <Chat>
                <div className="mb-16">{t('Check out these savings!')}</div>
                <div>
                  <SlickSlider>
                    <SliderCard
                      title={t('Approximate Lifetime Savings')}
                      content={`$${formatNumber(data.savings)}`}
                      subcontent="Over 25 years"
                    />
                    <SliderCard
                      title={t('Environmental Impact')}
                      content={`${data.tree_seedlings}`}
                      subcontent="Trees grown for 10 yrs"
                    />
                    <SliderCard
                      title={t('Recommended System')}
                      content={
                        theme.price_system_display || !theme.is_premier_account
                          ? `${data.install_size_min}-${data.install_size_max} kW`
                          : `${data.install_size} kW`
                      }
                      subcontent={t('({{panels_min}}-{{panels_max}} panels)', {
                        panels_min: data.panels_min,
                        panels_max: data.panels_max,
                      })}
                    />
                  </SlickSlider>
                </div>
              </Chat>
              {step > 1 && (
                <Chat onShow={() => this.setState({ show: true })}>
                  {t('Ready to see ballpark pricing?')}
                </Chat>
              )}
            </div>
            <div className="form-actions">
              <Button outline onClick={this.handleBack}>
                <BackIcon />
              </Button>
              <Button full disabled={!show || saving} onClick={this.handleNext}>
                {t("I'm ready!")}
              </Button>
            </div>
          </div>
        </StyledResult1Variation3>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui: { theme } }) => ({
  user: data,
  saving,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Result1Variation3)
);
