import React from 'react';
import PropTypes from 'prop-types';
import RDatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { noop } from 'common/utils';
import { es } from 'date-fns/locale';
import { getLocalStorageItem } from 'common/localStorage';
import StyledDatePicker from './styled';

const DatePicker = ({ value, onChange, ...rest }) => {
  const appLanguage = getLocalStorageItem('i18nextLng');
  if (appLanguage !== 'en')
    registerLocale(appLanguage, appLanguage === 'es' && es);
  return (
    <StyledDatePicker className="datepicker">
      <RDatePicker
        locale={appLanguage}
        inline
        selected={value}
        onChange={onChange}
        onSelect={onChange}
        useWeekdaysShort
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="datepicker-toolbar">
            <BackIcon
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className="datepicker-prev-month"
            />
            <span className="datepicker-current-month">
              {moment(date).format('MMMM')}
            </span>
            <BackIcon
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className="datepicker-next-month"
            />
          </div>
        )}
        renderDayContents={(_, date) => <span>{moment(date).format('D')}</span>}
        {...rest}
      />
    </StyledDatePicker>
  );
};

DatePicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  filterDate: PropTypes.func,
};

DatePicker.defaultProps = {
  value: new Date(),
  onChange: noop,
  filterDate: noop,
};

export default DatePicker;
