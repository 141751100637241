import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultLayout from 'components/DefaultLayout';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import MaterialSlider from 'components/MaterialSlider';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import StyledCalculate2 from './styled';

const scale = (x) => Math.floor(Math.round(x ** 3 / 5)) * 5;
const reverseScale = (y) => Math.cbrt(y);
const MIN_BILL = 30;
const MAX_BILL = 1000;

class Calculate2 extends Component {
  constructor(props) {
    super(props);

    const {
      user: { avg_bill, is_instant_estimate },
    } = this.props;

    this.state = {
      bill: reverseScale(avg_bill || MIN_BILL),
      show: false,
      is_instant_estimate,
    };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/start');
  };

  handleNext = async () => {
    const { actions } = this.props;

    let { bill } = this.state;
    const { is_instant_estimate } = this.state;
    bill = scale(bill);
    if (bill === 0) {
      return;
    }

    let step;
    let go_step;

    if (is_instant_estimate) {
      go_step = '/result3';
      step = '/calculate2';
    } else if (bill <= 30 || bill >= 700) {
      go_step = '/calculate21';
      step = '/calculate2';
    } else {
      go_step = '/calculate3';
      step = go_step;
    }

    actions.setUserData({
      avg_bill: bill,
      is_commercial: false,
      step,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(go_step);
  };

  handleChange = (value) => {
    this.setState({ bill: value });
  };

  render() {
    const { bill, show, is_instant_estimate } = this.state;
    const { saving, t } = this.props;
    const timeout = is_instant_estimate ? 0 : undefined;

    return (
      <DefaultLayout>
        <StyledCalculate2 className="content calculate-2-page">
          <div className="form">
            <div className="form-content">
              <div className="mb-20 w100">
                <ProgressBar value={50} step={2} />
              </div>

              <Chat
                onShow={() => this.setState({ show: true })}
                timeout={timeout}
              >
                <div className="mb-16">
                  {t('What is your average electric bill payment?')}
                </div>
              </Chat>

              {show && (
                <div className="mb-16">
                  <MaterialSlider
                    min={reverseScale(MIN_BILL)}
                    max={reverseScale(MAX_BILL)}
                    step={0.01}
                    scale={scale}
                    value={bill}
                    onChange={this.handleChange}
                  />
                </div>
              )}
            </div>

            <div className="form-actions">
              {!is_instant_estimate && (
                <Button outline onClick={this.handleBack}>
                  <BackIcon />
                </Button>
              )}

              <Button
                type="submit"
                full
                disabled={!show || bill === 0 || saving}
                onClick={this.handleNext}
                selected
              >
                {t('Calculate savings')}
              </Button>
            </div>
          </div>
        </StyledCalculate2>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data, saving } }) => ({
  user: data,
  saving,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Calculate2)
);
