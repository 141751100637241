/* eslint-disable react/jsx-key */
import React from 'react';
import cls from 'classnames';
import Button from 'components/Button';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import PlacesAutocomplete from 'components/PlaceAutocomplete';
import { loadGoogleMaps } from 'common/utils';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StyledWrapper from './styled';
import { useOutlinedTextFieldStyles } from '../TextInput/styled';

const GoogleZipCode = ({
  theme,
  value,
  name,
  onChange,
  onSelect,
  placeholder,
  error,
  autoFocus = true,
}) => {
  const [t] = useTranslation('translation');
  React.useEffect(() => {
    loadGoogleMaps(() => {
      if (window.initializePlaceAutocomplete)
        window.initializePlaceAutocomplete();
    });
  }, []);

  const handleChange = (zipcode) => {
    if (zipcode.length > 5) return;
    onChange({ target: { value: zipcode, name } });
  };

  const handleSelect = (zipcode, placeId) => {
    geocodeByPlaceId(placeId)
      .then((places) => {
        const place = places[0];
        let updated;
        if (place && place.address_components) {
          updated = place;
        }
        onSelect({ target: { value: zipcode, name } }, updated);
      })
      .catch((err) => {
        onSelect({ target: { value: zipcode, name } });
        console.error('Error', err);
      });
  };

  const classes = useOutlinedTextFieldStyles({ theme });

  const handleButtonClick = (suggestion) => {
    if (!suggestion) {
      return;
    }
    handleSelect(suggestion.description, suggestion.placeId);
  };

  return (
    <PlacesAutocomplete
      autoFocus={autoFocus}
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
      googleCallbackName="initializePlaceAutocomplete"
      searchOptions={{
        types: ['(regions)'],
        componentRestrictions: { country: 'us' },
      }}
      onError={(status, _clearSuggestions) => {
        switch (status) {
          case window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS: {
            _clearSuggestions();
            break;
          }
          default: {
            console.error(
              '[react-places-autocomplete]: error happened when fetching data from Google Maps API.\nPlease check the docs here (https://developers.google.com/maps/documentation/javascript/places#place_details_responses)\nStatus: ',
              status
            );
            break;
          }
        }
      }}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
        getFirstSuggestion,
      }) => (
        <StyledWrapper className="input-group" error={error}>
          <div className="google-zipcode-container">
            <TextField
              {...getInputProps({
                className: cls('google-zipcode-input', {
                  'google-zipcode-input--open':
                    loading || suggestions.length > 0,
                }),
              })}
              label={placeholder}
              placeholder=""
              variant="outlined"
              className={classes.outlinedTextField}
              type="tel"
            />
            <div
              className={cls('google-zipcode-dropdown-container', {
                'google-zipcode-dropdown-container--open':
                  loading || suggestions.length > 0,
              })}
            >
              {loading ? (
                <div className="google-zipcode-suggestion-item">Loading...</div>
              ) : (
                <ul role="listbox" className="google-zipcode-suggestions-list">
                  {suggestions.map((suggestion, index) => {
                    const className = cls('google-zipcode-suggestion-item', {
                      'google-zipcode-suggestion-item--active':
                        suggestion.active,
                    });
                    return (
                      <li
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                        key={index}
                      >
                        <div>{suggestion.description}</div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
          <Button full onClick={() => handleButtonClick(getFirstSuggestion())}>
            {t('Start Estimate')}
          </Button>
        </StyledWrapper>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleZipCode;
