import * as APIMock from 'common/api.mock';
import get from 'lodash/get';
import {
  findClosestFinancialDataIndex,
  extractValuesFromPlace,
  loadGoogleMaps,
  addCustomScripts,
} from 'common/utils';
import * as UIActions from 'store/actions/ui';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import * as Sentry from '@sentry/react';
import omit from 'lodash/omit';
import { getLocalStorageItem } from 'common/localStorage';
import toNumber from 'lodash/toNumber';
/**
 * Action Types
 */
export const STORE_SESSION = 'STORE_SESSION';

export const LOAD_SESSION_REQUEST = 'LOAD_SESSION_REQUEST';
export const LOAD_SESSION_SUCCESS = 'LOAD_SESSION_SUCCESS';
export const LOAD_SESSION_FAILURE = 'LOAD_SESSION_FAILURE';
export const SAVE_FINANCIAL_ANALYSES = 'SAVE_FINANCIAL_ANALYSES';

export const SAVE_SESSION_REQUEST = 'SAVE_SESSION_REQUEST';
export const SAVE_SESSION_SUCCESS = 'SAVE_SESSION_SUCCESS';
export const SAVE_SESSION_FAILURE = 'SAVE_SESSION_FAILURE';
export const INITIALIZE_SESSION = 'INITIALIZE_SESSION';

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';

/**
 * Action Creators
 */

export const setUserData = (user) => ({
  type: LOAD_SESSION_SUCCESS,
  user,
});

export const storeUserData = (user) => ({
  type: STORE_SESSION,
  user,
});

export const saveUserData = (user) => ({
  type: SAVE_SESSION_SUCCESS,
  user,
});

export const fireCustomScriptCodes = () => {
  return async (_, getState) => {
    const {
      ui: { theme },
    } = getState();

    try {
      const leadStatuses = await APIMock.getCustomScriptsCode();
      const firedStatuses = [];

      if (!leadStatuses) return;
      leadStatuses.forEach((leadStatus) => {
        let custom_code = null;

        if (leadStatus === 'visitor') custom_code = theme.custom_code_visitor;
        else if (leadStatus === 'lead') custom_code = theme.custom_code_lead;
        else if (leadStatus === 'disqualified_lead')
          custom_code = theme.custom_code_disqualified_lead;
        else if (leadStatus === 'qualified_lead')
          custom_code = theme.custom_code_qualified_lead;
        else if (leadStatus === 'appointment')
          custom_code = theme.custom_code_appointment;
        else if (leadStatus === 'appointment_with_bill')
          custom_code = theme.custom_code_utility_bill;

        if (custom_code) {
          addCustomScripts(custom_code);
          firedStatuses.push(leadStatus);
        }
      });

      if (firedStatuses.length)
        await APIMock.setCustomScriptsCode(firedStatuses);
    } catch (error) {
      console.error(error);
    }
  };
};

export const createSession = (variation = null) => {
  return async (dispatch) => {
    await APIMock.createSession(variation);
    dispatch(fireCustomScriptCodes());
  };
};

export const saveSession = (fromWidget = false) => {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_SESSION_REQUEST });
    const {
      user: { data },
    } = getState();
    if (data) {
      // APIMock.saveSessionToStorage(data);
      try {
        getLocalStorageItem('sessionId');
        if (!fromWidget) {
          const variation = getLocalStorageItem('variation');
          if (variation && variation !== 'current') data.variation = variation;
        }

        const response = await APIMock.storeSession(data);
        dispatch(fireCustomScriptCodes());
        dispatch(saveUserData(response));
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        dispatch({ type: SAVE_SESSION_FAILURE });
      }
    }
  };
};

export const calculateData = () => {
  return async (dispatch, getState) => {
    try {
      const {
        user: {
          data: {
            avg_bill,
            zip_code,
            city,
            state,
            country,
            rooftop_latitude,
            rooftop_longitude,
          },
          financialAnalyses,
        },
        ui: { theme },
      } = getState();
      const params = {
        avg_bill,
        zipcode: zip_code,
        city,
        state,
        country,
        rooftop_latitude,
        rooftop_longitude,
      };

      // find closes value
      const minId = findClosestFinancialDataIndex(financialAnalyses, avg_bill);
      if (minId !== -1) {
        const item = financialAnalyses[minId];

        params.local_incentive =
          (theme.is_state_incentive &&
            toNumber(get(item, 'financialDetails.stateIncentive.units', 0))) +
          (theme.is_utility_incentive &&
            toNumber(get(item, 'financialDetails.utilityIncentive.units', 0)));
      }

      if (theme) {
        if (theme.dollar_per_watt) {
          params.price_per_w = theme.dollar_per_watt;

          // get custom dollar per watt
          const data = await APIMock.getCustomPrice(state);
          if (data.amount) params.price_per_w = data.amount;
        }
        if (theme.offset) {
          params.bill_offset = theme.offset / 100.0;
        }
        if (theme.product_apr) {
          params.financing_rate = theme.product_apr / 100.0;
        }
        if (theme.product_term) {
          params.financing_term = theme.product_term;
        }
      }

      const customEscalation = await APIMock.getCustomEscalation(state);
      if (customEscalation.data && customEscalation.data.escalation) {
        params.escalation =
          parseFloat(customEscalation.data.escalation) / 100.0;
      }

      // get custom incentive to pass it to solar calculator API
      const { data: data1 } = await APIMock.getCustomIncentive(zip_code);

      params.custom_incentive = data1.custom_incentive;

      if (parseFloat(data1.pbi) !== 0.0) {
        params.performance_based_incentive = data1.pbi;
        params.performance_based_incentive_lifespan =
          parseFloat(data1.lifespan) !== 0.0 ? data1.lifespan : 10;
      }

      // lat, lng, city, state, country, zipcode
      // calculate solar savings
      let t;
      try {
        t = await APIMock.calculate(params);
      } catch (e) {
        t = await APIMock.calculate(params);
      }
      let { data: data2 } = t;

      data2 = omit(data2, 'city');
      data2 = omit(data2, 'state');
      dispatch(setUserData(data2));
      await dispatch(saveSession());
    } catch (error) {
      console.error(error);
      // navigate to inactive company page
      dispatch(UIActions.navigateTo('/inactive-company'));
      Sentry.captureException(error);
    }
    return Promise.resolve();
  };
};

export const loadSession = () => {
  return async (dispatch) => {
    let userSession;
    // userSession = APIMock.getSessionFromStorage();
    dispatch({
      type: LOAD_SESSION_REQUEST,
    });
    try {
      if (APIMock.getResumeKey()) {
        await APIMock.resumeSession();
      }
    } catch (error) {
      console.error(error);
    }
    try {
      if (APIMock.getProposalKey()) {
        userSession = await APIMock.getProposal();
      } else {
        userSession = await APIMock.loadSession();
      }
      dispatch(fireCustomScriptCodes());
      dispatch(setUserData(userSession));
    } catch (error) {
      dispatch({ type: LOAD_SESSION_FAILURE });
    }
  };
};

export const uploadBills = (data) => {
  return async () => {
    try {
      await APIMock.uploadFiles(data);
    } catch (error) {
      console.error(error);
    }
  };
};

export const sendUtilityBillEmail = () => {
  return async () => {
    try {
      await APIMock.sendUtilityBillEmail();
    } catch (error) {
      console.error(error);
    }
  };
};

export const answerQualifyQuestion = (questionId, answerId) => {
  return async () => {
    try {
      const data = await APIMock.answerQualifyQuestion(questionId, answerId);
      return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  };
};

export const fetchUtilityBills = (data) => {
  return async () => {
    try {
      await APIMock.fetchUtilityBills(data);
    } catch (error) {
      console.error(error);
    }
  };
};

export const trackForwardProgress = () => {
  return async () => {
    try {
      await APIMock.trackForwardProgress();
    } catch (error) {
      console.error(error);
    }
    return null;
  };
};

export const trackPhoneClick = () => {
  return async () => {
    try {
      await APIMock.trackPhoneClick();
    } catch (error) {
      console.error(error);
    }
    return null;
  };
};

export const loadFromAddressWidget = (theme) => {
  let { variation } = theme;

  if (variation === 'demand-iq-variation-2') {
    variation = null;
  }

  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_SESSION_REQUEST });
      await dispatch(createSession(null));

      await loadGoogleMaps(async () => {
        const { placeId } = APIMock.getDefaultAddressData();

        try {
          const places = await geocodeByPlaceId(placeId);
          const place = places[0];

          // extract country, state, address, zip_code, rooftop_latitude, rooftop_longitude from Google place object
          const values = extractValuesFromPlace(place);

          // verify that zip_code is in service
          let step = '/inactive-company';
          const newVariation =
            variation === undefined || variation === null ? null : variation;
          if (values && values.zip_code) {
            const { status, is_coverage_area } = await APIMock.verifyZipCode(
              values.zip_code
            );
            step = status ? '/calculate12' : '/getnotified1';
            if (status && is_coverage_area) step = '/calculate2';
          }

          // Save sessions
          dispatch(storeUserData({ ...values, step, variation: newVariation }));
          await dispatch(saveSession(true));
        } catch (e) {
          dispatch(loadSession());
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const loadFromZipcodeWidget = (theme) => {
  const variation = theme.variation || 'demand-iq-variation-2';
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_SESSION_REQUEST });
      await dispatch(createSession(null));
      const { zipcode, lat, lng } = APIMock.getDefaultZipcode();
      let zipcodeForVerify = zipcode || '';

      const location = { lat, lng };
      const getZipcodeFromLocation = async () => {
        const goecoder = new window.google.maps.Geocoder();
        await goecoder.geocode(
          {
            location,
          },
          async (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              const result = results[0];
              const values = await extractValuesFromPlace(result);
              zipcodeForVerify = values.zip_code;
            }
          }
        );
      };
      if (zipcodeForVerify === '') {
        if (window.google === undefined || window.google.maps)
          loadGoogleMaps(getZipcodeFromLocation);
        else getZipcodeFromLocation();
      }

      let step = '/inactive-company';
      try {
        // verify that zip_code is in service
        if (zipcodeForVerify !== '') {
          const { status } = await APIMock.verifyZipCode(zipcodeForVerify);
          step = status ? '/calculate19' : '/getnotified1';
        }
        dispatch(
          storeUserData({
            rooftop_latitude: lat,
            rooftop_longitude: lng,
            step,
            variation,
            zip_code: zipcode,
          })
        );
        await dispatch(saveSession(true));
      } catch (e) {
        dispatch(loadSession());
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const saveFinancialAnalyses = (payload) => {
  return {
    type: SAVE_FINANCIAL_ANALYSES,
    payload,
  };
};

export const saveCalendlyData = (calendly) => ({
  type: GET_EVENT_SUCCESS,
  calendly,
});

export const getEventInfo = (company, event_url) => {
  return async (dispatch) => {
    dispatch({ type: GET_EVENT_REQUEST });
    try {
      const param = {
        event_url,
      };

      const response = await APIMock.getEventData(company, param);
      const { data } = response;
      dispatch(saveCalendlyData(data));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      dispatch({ type: GET_EVENT_FAILURE });
    }
  };
};

export const initializeSession = () => {
  return {
    type: INITIALIZE_SESSION,
  };
};

export const notifyOfNewLead = () => {
  return async () => {
    try {
      await APIMock.notifyOfNewLead();
    } catch (error) {
      console.error(error);
    }
  };
};
