import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInput';
import Chat from 'components/Chat';
import Tooltip from 'components/Tooltip';
import ProgressBar from 'components/ProgressBar';
import { ReactComponent as TooltipIcon } from 'assets/images/icon-tooltip.svg';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import {
  verifyPhoneNumber,
  verifyEmail,
  sendVerificationCode,
  lookupPhoneNumber,
} from 'common/api.mock';
import { getNames } from 'common/utils';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import InputMask from 'react-input-mask';
import {
  setUserData,
  saveSession,
  trackFacebookPixel,
  navigateTo,
  trackForwardProgress,
} from '../../../store/actions';
import { Result2 as StyledResult2, Legal } from './styled';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  // .matches(/^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/),
  email: Yup.string().strict(false).trim().email().required('Required'),
  phone: Yup.string().strict(false).trim().required('Required'),
});

const CustomInput = ({ ...props }) => (
  <InputMask {...props}>{() => <TextInput {...props} />}</InputMask>
);

class Result2 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');
    this.state = {
      loading: false,
      loaded: false,
      errors: null,
      jornaya_lead_id: window.jornaya_leadid_token,
      variation: VARIATION_OPTIONS[variation],
    };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/result1');
  };

  handleNext = async (values, { setFieldError, setValues }) => {
    let { phone, email, name } = values;

    // trim phone, email before submitting
    phone = phone.trim();
    email = email.trim();
    name = name.trim();
    setValues({ phone, email, name });

    const { first: first_name, last: last_name } = getNames(name);
    const { actions, user, theme, t } = this.props;

    const { jornaya_lead_id } = this.state;
    this.setState({ loading: true, errors: null });
    const [phoneRes, emailRes, lookupRes] = await Promise.all([
      verifyPhoneNumber(phone),
      verifyEmail(email),
      lookupPhoneNumber({ phone }),
    ]);
    const errors = [];

    if (!first_name) {
      errors.push(t('Please provide your full name.'));
      setFieldError('name', 'Invalid');
    }
    if (!last_name) {
      errors.push(t('Please provide your last name.'));
      setFieldError('name', 'Invalid');
    }

    if (!phoneRes || !phoneRes.success || !lookupRes) {
      errors.push(
        t('That number does not seem to be a valid mobile phone number.')
      );
      setFieldError('phone', 'Invalid');
    }
    if (!emailRes || !emailRes.success) {
      errors.push(t('That email does not seem to be a valid email.'));
      setFieldError('email', 'Invalid');
    }
    if (errors.length > 0) {
      this.setState({ loading: false, loaded: true, errors });
    } else {
      const { is_phone_verification_required } = theme;
      actions.setUserData({
        first_name,
        last_name,
        email,
        phone: phoneRes.data.national_format,
        jornaya_lead_id,
        trustedform_cert_url: window.trustedForm.id
          ? `https://cert.trustedform.com/${window.trustedForm.id}`
          : '',
        step: is_phone_verification_required ? '/phoneverify' : '/result3',
      });

      actions.trackForwardProgress();
      await actions.saveSession();

      const { is_phone_verified } = user;

      if (is_phone_verification_required && !is_phone_verified) {
        const { detail } = await sendVerificationCode({ phone });
        if (!detail) await actions.navigateTo('/result3');
        else await actions.navigateTo('/phoneverify');
      } else {
        await actions.navigateTo('/result3');
      }
    }
  };

  getFullName = () => {
    const { user } = this.props;
    const name = [];
    if (user.first_name) {
      name.push(user.first_name);
    }
    if (user.last_name) {
      name.push(user.last_name);
    }
    return name.join(' ');
  };

  render() {
    const { user, theme, t } = this.props;
    const { loading, loaded, errors, variation } = this.state;
    const errorMsg = errors ? errors.join('\n') : '';

    return (
      <>
        <variation.header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledResult2 className="content result-2-page">
              <Formik
                initialValues={{
                  name: this.getFullName() || '',
                  email: user.email || '',
                  phone: user.phone || '',
                }}
                validationSchema={formSchema}
                onSubmit={this.handleNext}
                validateOnMount
              >
                {({ isValid, isSubmitting }) => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <div className="mb-20 w100">
                          <ProgressBar value={100} step={4} />
                        </div>
                        <Chat
                          mode={loaded || loading ? 'loading' : 'timeout'}
                          loading={loading}
                        >
                          <div className="mb-16">
                            {!loaded ? (
                              <span>
                                <span className="bold">
                                  {t(
                                    'Your online estimate is on the next page!'
                                  )}
                                </span>{' '}
                                {t('Now unlock live pricing!')}
                              </span>
                            ) : (
                              errorMsg
                            )}
                            &nbsp;
                            <TooltipIcon data-for="tooltip" data-tip />
                            <Tooltip id="tooltip" place="bottom">
                              <div className="tooltip-title">
                                {t('We do not sell your information.')}
                              </div>
                              <div className="tooltip-content">
                                {t(
                                  'Our online savings estimates are conservative. Typically, we can improve your savings estimate with more info about your project.'
                                )}
                              </div>
                            </Tooltip>
                          </div>
                          <div className="mb-16">
                            <Field
                              name="name"
                              type="text"
                              placeholder={t('First and Last Name')}
                              theme={theme}
                              component={TextInput}
                              autoFocus
                            />
                          </div>
                          <div className="mb-16">
                            <Field
                              name="email"
                              type="email"
                              placeholder={t('Email')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <div className="mb-16">
                            <Field>
                              {({ field, form }) => (
                                <CustomInput
                                  mask="(999) 999-9999"
                                  name="phone"
                                  type="tel"
                                  placeholder={t('Mobile phone number')}
                                  theme={theme}
                                  value={field.value.phone}
                                  onChange={field.onChange}
                                  field={field}
                                  form={form}
                                />
                              )}
                            </Field>
                          </div>
                        </Chat>
                      </div>
                      <div className="form-actions">
                        <Button outline onClick={this.handleBack}>
                          <BackIcon />
                        </Button>
                        <Button
                          type="submit"
                          full
                          disabled={!isValid || isSubmitting}
                        >
                          {t('Unlock Estimate')}
                          <ArrowRightIcon />
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </StyledResult2>
            {!loading && (
              <Legal className="form-legal">
                <label>
                  <input type="hidden" id="leadid_tcpa_disclosure" />
                  {t(
                    'To help you go solar, we need your approval to contact you. By clicking above, you agree that {{company}} may email, call and text you about {{company}} products at the phone number you entered above, using pre-recorded messages or an autodialer, even if your number is on a "Do Not Call" list. Msg & data rates may apply to text messages. Consent for calls & texts is optional. You can opt-out anytime.',
                    {
                      company: theme.company_name,
                    }
                  )}
                </label>
              </Legal>
            )}
            <variation.footer showLegal />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Result2)
);
