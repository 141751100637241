import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { ReactComponent as QuestionIcon } from 'assets/images/icon-question.svg';
import { ReactComponent as PhoneIcon } from 'assets/images/icon-phone.svg';
import iconLoading from 'assets/images/icon-loading.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackPhoneClick,
} from 'store/actions';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { setLocalStorageItem } from 'common/localStorage';
import { v4 as uuidv4 } from 'uuid';
import { getVisibleHeader } from 'common/api.mock';
import StyledHeader from './styled';

const Header = ({ showRestartButton }) => {
  const [t] = useTranslation('translation');
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.ui.theme);
  const [showDialog, setShowDialog] = useState(false);
  const phoneNumber = theme.advertise_phone
    ? parsePhoneNumberFromString(theme.advertise_phone, 'US')
    : null;
  const uri = phoneNumber ? phoneNumber.getURI() : '';

  const user = useSelector((state) => state.user.data);

  let restartAble = true;
  let restartStep = '/start';

  if (user.is_instant_estimate) {
    restartStep = '/result3';

    if (user.step === '/result3' || user.step === 'result3') {
      restartAble = false;
    }
  }

  const openPhone = () => {
    if (!uri) {
      return;
    }
    dispatch(trackPhoneClick());
    window.open(uri, '_top');
  };

  const handleNext = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleRestart = async () => {
    handleClose();

    const eventID = uuidv4();
    setLocalStorageItem('eventID', eventID);
    dispatch(
      setUserData({
        back_step: '/',
        step: restartStep,
        urjanet_status: null,
      })
    );
    await dispatch(saveSession());
    dispatch(navigateTo(restartStep));
  };

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const visibleHeader = getVisibleHeader();

  if (!visibleHeader) {
    return <></>;
  }

  return (
    <>
      <StyledHeader className="header">
        <a
          className="header-left"
          href={theme.website_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={theme.logo}
            alt={theme.company_name}
            className="logo"
            id="logo"
          />
        </a>
        {showRestartButton && restartAble && (
          <div className="header-center">
            <div className="" onClick={handleNext}>
              <img src={iconLoading} alt="loading" className="loading" />
            </div>
          </div>
        )}

        <div className="header-right">
          {theme.advertise_phone && (
            <Button
              className="phone-number"
              outline
              onClick={openPhone}
              type="button"
            >
              <PhoneIcon />
              {isMobile ? t('Call us') : theme.advertise_phone}
            </Button>
          )}
        </div>
      </StyledHeader>
      <Modal showCloseIcon onClose={handleClose} isOpen={showDialog}>
        <div className="my-32">
          <QuestionIcon />
        </div>
        <div className="modal-title text-center">
          {t("Are you sure you'd like to restart?")}
        </div>
        <div className="modal-content">
          <div className="mb-32 text-center">
            {t('Your progress will be lost.')}
          </div>
          <div className="mb-32">
            <Button full onClick={handleRestart}>
              {t('Yes, start over')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

Header.propTypes = {
  showRestartButton: PropTypes.bool,
};

Header.defaultProps = {
  showRestartButton: true,
};

export default Header;
