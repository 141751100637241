import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icon-close-line.svg';

const TermsModal = ({ onClose }) => {
  return (
    <div className="terms-overlay">
      <div className="terms-main">
        <CloseIcon className="terms-close" onClick={onClose} />
        <div className="terms-container">
          <h3 className="text-center">Demand IQ End User Terms</h3>
          <p>
            These Demand IQ End User Terms ("<strong>User Terms</strong>") is a
            binding agreement between you ("<strong>End User</strong>" or "
            <strong>you</strong>") and Sunlytix LLC d/b/a Demand IQ ("
            <strong>Demand IQ</strong>", "<strong>we</strong>", "
            <strong>us</strong>", or "<strong>our</strong>"). These User Terms
            govern your use of the Demand IQ website application (including all
            related documentation, the "<strong>Service</strong>"). The Service
            is licensed, not sold, to you.
          </p>
          <p className="text-uppercase">
            BY USING THE SERVICE OR OTHERWISE AGREEING TO THESE USER TERMS, YOU
            (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE USER TERMS;
            (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING
            AGREEMENT; AND (C) ACCEPT THESE USER TERMS AND AGREE THAT YOU ARE
            LEGALLY BOUND BY TO THESE USER TERMS. IF YOU DO NOT AGREE TO THESE
            TERMS, DO NOT USE OR ACCESS THE SERVICE.
          </p>
          <p className="text-uppercase">
            PLEASE REVIEW THESE USER TERMS, AS THEY AFFECT YOUR RIGHTS REGARDING
            YOUR USE OF THE SERVICES AND YOUR ABILITY TO BRING CLAIMS AGAINST
            DEMAND IQ.
          </p>
          <ol>
            <li>
              <span className="text-underline">Service Use; Content</span>. The
              Service provides you with access to resources, tools, products,
              services, and related features, functionality, and content
              (collectively, "<strong>Content</strong>"), some of which may be
              accessible on, offered through, or hosted on Demand IQ's or its
              customer's websites. The Content may display, include, or make
              available third-party content (including data, information,
              applications, and other products, services, and/or materials) or
              provide links to third-party websites or services, including
              through third-party advertising. Your access to and use of the
              Service and all related Content is at your own risk. All Content
              are provided to you without any warranty or guarantee of their
              accuracy, completeness, timeliness, validity, compliance,
              legality, decency, quality, or any other aspect thereof. Demand IQ
              does not assume and will not have any liability or responsibility
              to you or any other person or entity for your use of the Service
              and its Content. All third-party materials, services and links
              thereto contained within the Service are provided solely as a
              convenience to you, and you access and use them entirely at your
              own risk and subject to such third parties' terms and conditions.
            </li>
            <li>
              <span className="text-underline">Privacy</span> Demand IQ may make
              the service available on the website of a Demand IQ customer, in
              which case that customer, and not Demand IQ, will be the
              controller of any personal information that you provide through
              the Service. If you have any questions regarding how your personal
              information may be processed or the rights you may have regarding
              your personal information, we encourage you to review the privacy
              policy set forth on the website where you access the Service. If
              you would like more information about Demand IQ's own information
              processing and privacy practices, you may access the Demand IQ
              Privacy Policy at{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://demand-iq.com/privacy-policy/"
              >
                https://demand-iq.com/privacy-policy/
              </a>
              .
            </li>
            <li>
              <span className="text-underline">License Grant</span>. Subject to
              these User Terms, Demand IQ grants you a limited, non-exclusive,
              and nontransferable license to, strictly in accordance with these
              User Terms, (a) access and use the Service for your personal use;
              and (b) access and, to the extent applicable, download the Content
              made available in or otherwise accessible through the Service.
            </li>
            <li>
              <span className="text-underline">License Restrictions</span>. You
              shall not: (a) copy the Service; (b) modify, translate, adapt, or
              otherwise create derivative works or improvements, whether or not
              patentable, of the Service; (c) reverse engineer, disassemble,
              decompile, decode, or otherwise attempt to derive or gain access
              to the source code of the Service or any part thereof; (d) remove,
              delete, alter, or obscure any trademarks or any copyright,
              trademark, patent, or other intellectual property or proprietary
              rights notices from the Service, including any copy thereof; (d)
              rent, lease, lend, sell, sublicense, assign, distribute, publish,
              transfer, or otherwise make available the Service, or any features
              or functionality of the Service, to any third party for any
              reason, including by making the Service available on a network
              where it is capable of being accessed by more than one device at
              any time; or (e) remove, disable, circumvent, or otherwise create
              or implement any workaround to any copy protection, rights
              management, or security features in or protecting the Service.
            </li>
            <li>
              <span className="text-underline">Reservation of Rights</span>. You
              acknowledge and agree that the Service is provided under license,
              and not sold, to you. You do not acquire any ownership interest in
              the Service or any other rights thereto under these User Terms
              other than to use the Service in accordance with the license
              granted, and subject to all terms, conditions, and restrictions
              hereunder. Demand IQ and its customers, partners, licensors and
              service providers reserve and shall retain their entire right,
              title, and interest in and to the Service, including all
              copyrights, trademarks, and other intellectual property rights
              therein or relating thereto, except as expressly granted to you in
              these User Terms.
            </li>
            <li>
              <span className="text-underline">Geographic Restrictions</span>.
              You acknowledge that you may not be able to access all or some of
              the Content outside of the United States and that access thereto
              may not be legal by certain persons or in certain countries. If
              you access the Content from outside the United States, you are
              responsible for compliance with local laws.
            </li>
            <li>
              <span className="text-underline">Updates</span>. Demand IQ may
              from time to time in its sole discretion develop and provide
              Service updates, which may include upgrades, bug fixes, patches,
              other error corrections, and/or new features (collectively,
              including related documentation, "<strong>Updates</strong>").
              Updates may also modify or delete in their entirety certain
              features and functionality. You agree that Demand IQ has no
              obligation to provide any Updates or to continue to provide or
              enable any particular features or functionality. You further agree
              that all Updates will be deemed part of the Service and be subject
              to all terms and conditions of these User Terms.
            </li>
            <li>
              <span className="text-underline">Term and Termination</span>.
              These User Terms commence when you download, install, access, or
              otherwise use the Service and will continue in effect until
              terminated by you or Demand IQ as set forth in this Section. You
              may terminate these User Terms by discontinuing your use of the
              Service and deleting all Content that you may have downloaded.
              Demand IQ may terminate these User Terms at any time without
              notice, which Demand IQ may do in its sole discretion. In
              addition, these User Terms will terminate immediately and
              automatically without any notice if you violate any of the terms
              and conditions of these User Terms. Upon termination: all rights
              granted to you under these User Terms will also terminate; and you
              must cease all use of the Service. Termination will not limit any
              of Demand IQ's rights or remedies at law or in equity.
            </li>
            <li>
              <span className="text-underline">Disclaimer of Warranties</span>.{' '}
              <span className="text-uppercase">
                THE SERVICE IS PROVIDED TO YOU "AS IS" AND WITH ALL FAULTS AND
                DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT
                PERMITTED UNDER APPLICABLE LAW, DEMAND IQ, ON ITS OWN BEHALF AND
                ON BEHALF OF ITS AFFILIATES, CUSTOMERS, AND ITS AND THEIR
                RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS
                ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
                OTHERWISE, WITH RESPECT TO THE SERVICE AND ALL CONTENT,
                INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND
                WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
                PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE
                FOREGOING, DEMAND IQ PROVIDES NO WARRANTY OR UNDERTAKING, AND
                MAKES NO REPRESENTATION OF ANY KIND THAT THE SERVICE WILL MEET
                YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE,
                OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR
                SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR
                RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR
                DEFECTS CAN OR WILL BE CORRECTED. SOME JURISDICTIONS DO NOT
                ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR
                THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A
                CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS
                MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, YOU ACKNOWLEDGE AND
                AGREE THAT THIS LIMITATION OF LIABILITY SHALL BE ENFORCED TO THE
                FULLEST EXTENT PERMITTED BY LAW.
              </span>
            </li>
            <li>
              <span className="text-underline">Limitation of Liability</span>.{' '}
              <span className="text-uppercase">
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                WILL DEMAND IQ OR ITS AFFILIATES, CUSTOMERS, OR ANY OF ITS OR
                THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY
                LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO
                USE THE SERVICE OR THE CONTENT FOR ANY REASON, INCLUDING BUT NOT
                LIMITED TO PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST
                OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL,
                BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY
                OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
                OR PUNITIVE DAMAGES. THE FOREGOING LIMITATION WILL APPLY WHETHER
                SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING
                NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES
                WERE FORESEEABLE OR DEMAND IQ WAS ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN
                LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS
                OF LIABILITY MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, YOU
                ACKNOWLEDGE AND AGREE THAT THIS LIMITATION OF LIABILITY SHALL BE
                ENFORCED TO THE FULLEST EXTENT PERMITTED BY LAW.
              </span>
            </li>
            <li>
              <span className="text-underline">Indemnification</span>. You agree
              to indemnify, defend, and hold harmless Demand IQ and its
              customers and its and their officers, directors, employees,
              agents, affiliates, successors, and assigns from and against any
              and all losses, damages, liabilities, deficiencies, claims,
              actions, judgments, settlements, interest, awards, penalties,
              fines, costs, or expenses of whatever kind, including reasonable
              attorneys' fees, arising from or relating to your use or misuse of
              the Service or your breach of these User Terms, including but not
              limited to the information you submit or make available through
              the Service.
            </li>
            <li>
              <span className="text-underline">Severability</span>. If any
              provision of these User Terms is illegal or unenforceable under
              applicable law, the remainder of the provision will be amended to
              achieve as closely as possible the effect of the original term and
              all other provisions of these User Terms will continue in full
              force and effect.
            </li>
            <li>
              <span className="text-underline">Governing Law</span>. These User
              Terms are governed by and construed in accordance with the
              internal laws of the State of Colorado without giving effect to
              any choice or conflict of law provision or rule. Any legal suit,
              action, or proceeding arising out of or related to these User
              Terms or the Service shall be instituted exclusively in the
              federal courts of the United States or the courts of the State of
              Colorado in each case located in the city and county of Denver.
              You hereby irrevocably waive any and all objections to the
              exercise of jurisdiction over you by such courts and to venue in
              such courts.
            </li>
            <li>
              <span className="text-underline">
                Limitation of Time to File Claims
              </span>
              .{' '}
              <span className="text-uppercase">
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                RELATING TO THESE USER TERMS OR THE SERVICE MUST BE COMMENCED
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE
                SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
              </span>
            </li>
            <li>
              <span className="text-underline">Waiver</span>. No failure to
              exercise, and no delay in exercising, on the part of either party,
              any right or any power hereunder shall operate as a waiver
              thereof, nor shall any single or partial exercise of any right or
              power hereunder preclude further exercise of that or any other
              right hereunder. In the event of a conflict between these User
              Terms and any applicable purchase or other terms, the terms of
              these User Terms shall govern your use of the Services; provided
              that Demand IQ customers and their employees and representatives
              shall enter into a separate agreement to govern customer use of
              any Demand IQ services.
            </li>
            <li>
              <span className="text-underline">Entire Agreement</span>. These
              User Terms constitute the entire agreement between you and Demand
              IQ with respect to your use of the Service.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
