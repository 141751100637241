import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleAddress from 'components/Forms/GoogleAddress';
import Loading from 'components/Loading';
import imgSunroof from 'assets/images/logo-project-sunroof-grey.png';
import {
  setUserData,
  createSession,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { checkIfImageIsLoaded, extractValuesFromPlace } from 'common/utils';
import { verifyZipCode } from 'common/api.mock';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import StyledStart from './styled';

class Start extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: '',
      loading: true,
      variation: null,
    };
    const { i18n, user } = this.props;
    i18n.init({
      fallbackLng: user.lang,
    });
  }

  componentDidMount() {
    const { user, theme, actions } = this.props;
    if (user.session_id === undefined) {
      actions.createSession(theme.variation);
    }
    const paramVariation = getLocalStorageItem('variation');
    if (user.session_id && (user.variation || 'current') !== paramVariation) {
      actions.createSession(paramVariation);
    }
    this.initData();
  }

  initData = async () => {
    const images = ['/assets/images/map.jpg'];
    await checkIfImageIsLoaded(images);
    const variation = getLocalStorageItem('variation');
    this.setState({ loading: false, variation: VARIATION_OPTIONS[variation] });
  };

  handleSave = async (event, place) => {
    if (!place) {
      return;
    }
    this.setState({ address: event.target.value });
    const { actions } = this.props;

    const values = extractValuesFromPlace(place);

    let step = '/calculate12';
    // verify that zip_code is in service
    if (values && values.zip_code) {
      const { status, is_coverage_area } = await verifyZipCode(values.zip_code);
      step = status ? '/calculate12' : '/getnotified1';
      if (status && is_coverage_area) step = '/calculate2';
    } else if (values && !values.zip_code) {
      step = '/validatezipcode';
    } else {
      return;
    }

    actions.setUserData({
      ...values,
      step,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    actions.navigateTo(step);
  };

  handleChange = (event) => {
    this.setState({ address: event.target.value });
  };

  render() {
    const { theme, t } = this.props;
    const { address, loading, variation } = this.state;
    if (loading) {
      return <Loading />;
    }

    const language = getLocalStorageItem('i18nextLng');
    const avatar = /^es\b/.test(language)
      ? theme.spanish_ai_avatar
      : theme.ai_avatar;
    const ai_name = /^es\b/.test(language)
      ? theme.spanish_ai_name
      : theme.ai_name;
    let welcome_statement = /^es\b/.test(language)
      ? theme.spanish_welcome_statement
      : theme.welcome_statement;
    welcome_statement = welcome_statement.replace('{{ai_name}}', ai_name);

    return (
      <>
        <variation.header showRestartButton={false} />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledStart className="content start-page">
              <div className="form">
                <div className="form-content">
                  <div className="map-section">
                    <div className="map-block"></div>
                    <div
                      className="avatar"
                      style={{
                        backgroundImage: `url(${avatar})`,
                      }}
                    >
                      <span className="avatar-status"></span>
                    </div>
                  </div>
                  <div className="ai-section">
                    <div>
                      <div className="">
                        <span className="ai-greeting">
                          {welcome_statement ||
                            t(
                              "Hey! I'm {{ai_name}}. I'll get you a custom solar estimate in seconds using satellites!",
                              { ai_name }
                            )}
                        </span>
                      </div>
                      <GoogleAddress
                        theme={theme}
                        value={address}
                        onChange={this.handleChange}
                        onSelect={this.handleSave}
                        placeholder={t('Enter your street address to start!')}
                        name="address"
                      />
                      <div className="power-text">
                        <div className="power-text-inner">
                          <span>{t('Powered by')}</span>
                          <img src={imgSunroof} alt="sunroof" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </StyledStart>
            <variation.footer />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      createSession,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Start)
);
