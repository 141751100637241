import React from 'react';

const Loading = () => (
  <div className="loading-overlay">
    <div id="loading">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </div>
  </div>
);

export default Loading;
