import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import MaterialStepper from 'components/MaterialStepper';
import {
  getAvailableDays,
  getTheNearestAvailableDate,
  roundDate,
} from 'common/utils';
import { BUFFER_TIME } from 'common/constants';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from '../../../store/actions';
import StyledMeeting1 from './styled';

class Meeting1CFSVariation extends Component {
  constructor(props) {
    super(props);

    const { theme, user } = props;

    const variation = getLocalStorageItem('variation');

    const timezone =
      user.meeting_timezone ||
      (!theme.appointment_timezone ||
      theme.appointment_timezone === 'custom_homeowner_timezone'
        ? 'UTC'
        : theme.appointment_timezone) ||
      'UTC';

    const minDate = getTheNearestAvailableDate(
      theme.appointment_schedule,
      roundDate(
        moment().add(theme.appointment_time_buffer || BUFFER_TIME, 'hours')
      ),
      timezone
    ).format();
    const browserTz = moment.tz.guess(true);

    const startAt = moment.tz(minDate, 'YYYY-MM-DD', browserTz);
    const endAt = moment(startAt).add(
      (theme.appointment_time_buffer || BUFFER_TIME) >= 7 * 24 ? 21 : 14,
      'days'
    );

    this.state = {
      date: null,
      availableDays: getAvailableDays(theme.appointment_schedule),
      minDate: startAt,
      maxDate: endAt,
      variation: VARIATION_OPTIONS[variation],
    };
  }

  handleNext = async () => {
    const { actions } = this.props;
    const { date } = this.state;
    actions.setUserData({
      meeting_date: moment(date).format('YYYY-MM-DD'),
      step: '/meeting2',
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/meeting2');
  };

  filterDate = (date) => {
    const { availableDays } = this.state;
    return availableDays[moment(date).day()];
  };

  handleDateChange = async (value) => {
    this.setState({ date: value });

    const { actions } = this.props;
    actions.setUserData({
      meeting_date: moment(value).format('YYYY-MM-DD'),
      step: '/meeting2',
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/meeting2');
  };

  render() {
    const { date, minDate, maxDate, variation } = this.state;
    const { t, user } = this.props;

    return (
      <>
        <variation.header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledMeeting1 className="content meeting-1-page custom-stepper">
              <div className="form" id="form-meeting1">
                <div className="material-progress">
                  <MaterialStepper activeStep={1} variation={user.variation} />
                </div>
                <div className="form-content text-center">
                  <div className="form-title mb-16">
                    {t('What day works best?')}
                  </div>
                  <div className="form-subtext mb-16">
                    {t('Pick a day for a project consultant to call you.')}
                  </div>
                  <div className="form-datepicker mb-16">
                    <DatePicker
                      onChange={this.handleDateChange}
                      value={date}
                      filterDate={this.filterDate}
                      minDate={minDate.toDate()}
                      maxDate={maxDate.toDate()}
                    />
                  </div>
                </div>
              </div>
            </StyledMeeting1>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui: { theme } }) => ({
  user: data,
  saving,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Meeting1CFSVariation)
);
