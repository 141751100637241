/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import SunroofImage from 'components/SunroofImage';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
  saveFinancialAnalyses,
} from 'store/actions';
import { engineSolarFindBuilding } from 'common/api.mock';
import { formatNumber, checkIfSunroofIsLoaded } from 'common/utils';
import DefaultLayout from 'components/DefaultLayout';
import { Link } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import StyledWrapper from './styled';

class Calculate12 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      squareFeet: null,
      centerLat: null,
      centerLng: null,
      error: null,
      imageLoading: false,
      step: 0,
      saving: false,
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    const { theme, user } = this.props;
    if (!theme.is_sunroof_enabled) {
      this.setState({ loading: false, error: 'Skip sunroof' });
      this.handleNext();
      return Promise.resolve();
    }
    const { data: building, error } = await engineSolarFindBuilding(
      { latitude: user.rooftop_latitude, longitude: user.rooftop_longitude },
      theme.google_api_gateway_key
    );

    // add "Sunroof Shown" identifier for each end-user of Stella to Heap
    if (process.env.REACT_APP_TRACKING_ENV === 'production' && window.heap) {
      window.heap.addUserProperties({ 'Sunroof Shown': !!building });
    }

    if (!building) {
      const statusCode = get(error, 'response.data.error.code');
      const message = get(error, 'response.data.error.message');
      if (statusCode >= 400) {
        this.setState({ error: message, loading: false });
        this.handleNext();
      }
    } else {
      // save financial analyses from google api to store
      const { actions } = this.props;

      actions.saveFinancialAnalyses(
        get(building, 'solarPotential.financialAnalyses')
      );

      const squareFeet = formatNumber(
        Math.round(
          get(building, 'solarPotential.maxArrayAreaMeters2', null) * 10.764
        )
      );
      const centerLat = get(building, 'center.latitude', null);
      const centerLng = get(building, 'center.longitude', null);
      this.setState({
        loading: false,
        squareFeet,
        centerLat,
        centerLng,
      });

      // load google sunroof images
      this.setState({
        imageLoading: true,
        step: 1,
      });
      await checkIfSunroofIsLoaded(centerLat, centerLng);
      this.setState({
        imageLoading: false,
      });
    }
    return Promise.resolve();
  };

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/start');
  };

  handleNext = async () => {
    const { actions } = this.props;
    const step = '/calculate2';
    actions.setUserData({
      step,
    });
    this.setState({ saving: true });
    await actions.saveSession();
    this.setState({ saving: false });
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  handleShow = () => {
    const { error, loading } = this.state;
    if (!loading && error) {
      this.handleNext();
    }
  };

  handleResetHomeAddress = async () => {
    const { actions } = this.props;
    const step = '/resethome';
    actions.setUserData({
      step,
    });
    await actions.navigateTo(step);
  };

  render() {
    const {
      loading,
      squareFeet,
      centerLat,
      centerLng,
      error,
      imageLoading,
      step,
      saving,
    } = this.state;
    const { t } = this.props;

    return (
      <DefaultLayout>
        <StyledWrapper className="content calculate-12-page">
          <div className="form">
            <div className="form-content">
              <div className="mb-20 w100">
                <ProgressBar value={25} step={1} />
              </div>
              <Chat
                loading={loading || !!error}
                mode="loading"
                onShow={this.handleShow}
              >
                {!error && !!squareFeet && (
                  <div>
                    {squareFeet < 500
                      ? t('Your roof could be good for solar!')
                      : t(
                          'Your roof has about {{squareFeet}} sqft available for solar panels!',
                          { squareFeet }
                        )}
                  </div>
                )}
              </Chat>
              {step > 0 && (
                <Chat
                  loading={imageLoading || !!error}
                  mode="loading"
                  onShow={this.handleShow}
                >
                  {!error && centerLat && centerLng && (
                    <>
                      <div className="sunny">
                        <span>{t('Shady')}</span>
                        <div className="sunny-bar"></div>
                        <span>{t('Sunny')}</span>
                      </div>
                      <div className="sunroof-image-wrapper">
                        <SunroofImage lat={centerLat} lng={centerLng} />
                        <Link
                          href="#"
                          onClick={() => this.handleResetHomeAddress()}
                          underline="always"
                          variant="body1"
                        >
                          {t('Not your home?')}
                        </Link>
                      </div>
                    </>
                  )}
                </Chat>
              )}
            </div>
            {!loading && !error && (
              <div className="form-actions">
                <Button outline onClick={this.handleBack}>
                  <BackIcon />
                </Button>
                <Button
                  type="submit"
                  full
                  onClick={this.handleNext}
                  disabled={loading || imageLoading || saving}
                >
                  {t('Continue')}
                </Button>
              </div>
            )}
          </div>
        </StyledWrapper>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ ui, user: { data } }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
      saveFinancialAnalyses,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Calculate12)
);
