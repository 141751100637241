import styled from 'styled-components';

const StyledWrapper = styled.div`
  &.charger-option {
    width: 20.5rem;
    min-height: 14.125rem;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 1.875rem 1rem;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 479px) {
      width: calc(100vw - 5rem);
    }

    .charger-option-content {
      padding: 24px 20px 0 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    svg {
      width: 3rem;
      height: 3rem;
    }

    .option-header {
      font-size: 1rem;
      text-align: center;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.87);
      margin: 1rem 0px;
      font-weight: 500;
      letter-spacing: 0.07rem;
    }

    .option-description {
      font-size: 0.875rem;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      flex-grow: 1;
      align-items: center;
      display: flex;
      line-height: 1.25rem;

      span {
        font-weight: bold;
      }
    }

    .option-rate {
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin: 1rem 0;

      span {
        font-size: 1.275rem;
      }
    }

    .option-rate-title {
      font-weight: bold;
      font-size: 0.75rem;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin: 1rem 0;

      span {
        font-size: 0.875rem;
      }
    }

    .add-option {
      padding: 12px 0px;
      width: 100%;
      height: 40px;
      border-top: 1px solid #dcdcdc;
      font-weight: bold;
      font-size: 0.875rem;
      text-align: center;
      color: ${(props) => props.theme.color_scheme_light};
      cursor: pointer;
    }

    .add-option.selected {
      color: #000;
    }
  }

  &.charger-option.selected {
    background: ${(props) => props.theme.color_scheme_light};
  }
`;

export default StyledWrapper;
