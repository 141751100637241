import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function usePageViews() {
  const location = useLocation();
  const google_analytics_site_tag = useSelector(
    (state) => state.ui.theme?.google_analytics_site_tag
  );

  React.useEffect(() => {
    window.ga('stellaTracker.set', 'page', window.location.pathname);
    window.ga('stellaTracker.send', 'pageview');
    if (google_analytics_site_tag) {
      window.ga('set', 'page', window.location.pathname);
      window.ga('send', 'pageview');
    }
  }, [location, google_analytics_site_tag]);
}

export function PageView() {
  usePageViews();
  return null;
}
