import React from 'react';
import StyledCheckbox from './styled';

const Checkbox = ({ onChange, children }) => (
  <StyledCheckbox className="checkbox">
    {children}
    <input type="checkbox" onChange={onChange} />
    <span className="checkmark"></span>
  </StyledCheckbox>
);

export default Checkbox;
