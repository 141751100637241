import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultLayout from 'components/DefaultLayout';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import {
  calculateData,
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import StyledCalculate3 from './styled';

class Calculate3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      loading: true,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.handleCalculate();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleNext = () => {
    const { actions } = this.props;
    setTimeout(() => {
      actions.trackForwardProgress();
      actions.navigateTo('/result1');
    }, 2000);
  };

  handleCalculate = async () => {
    const { actions } = this.props;
    this.setState({ loading: true });
    await actions.calculateData();
    if (this._mounted) this.setState({ loading: false });
  };

  render() {
    const { step, loading } = this.state;
    const { t } = this.props;

    return (
      <DefaultLayout>
        <StyledCalculate3 className="content calculate-3-page">
          <div className="form">
            <div className="form-content">
              <div className="mb-20 w100">
                <ProgressBar value={75} step={3} />
              </div>
              <Chat
                mode="loading"
                loading={loading}
                onShow={() => this.setState({ step: 2 })}
              >
                {t('Hang on. I am calculating your savings...')}
              </Chat>
              {step >= 2 && (
                <Chat onShow={() => this.setState({ step: 3 })}>
                  {t('Sizing your solar system based on your bill...')}
                </Chat>
              )}
              {step >= 3 && (
                <Chat onShow={this.handleNext}>
                  {t('Checking on energy prices...')}
                </Chat>
              )}
            </div>
          </div>
        </StyledCalculate3>
      </DefaultLayout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      calculateData,
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(null, mapDispatchToProps)(Calculate3)
);
