import React from 'react';
import { ReactComponent as BatterIcon } from 'assets/images/battery-2-charge-line.svg';
import StyledWrapper from './styled';

const Battery = ({ title, description, cost, selected, onClick }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StyledWrapper className={`battery-option ${selected ? 'selected' : ''}`}>
        <div className="battery-option-content">
          <BatterIcon />
          <div className="option-header">{title}</div>
          <div className="option-description">{description}</div>
          <div className="option-rate">
            <span>+${cost}/</span>mo.
          </div>
        </div>
        <div
          className={`add-option ${selected ? 'selected' : ''}`}
          onClick={onClick}
        >
          {selected ? 'Remove' : 'Add'}
        </div>
      </StyledWrapper>
    </div>
  );
};

export default Battery;
