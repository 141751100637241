if (window && process.env.REACT_APP_TRACKING_ENV !== 'development') {
  window.setUniversalLeadId = (leadid) => {
    window.jornaya_leadid_token = leadid;
  };
  const script = document.createElement('script');
  script.id = 'LeadiDscript_campaign';
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.crossorigin = 'anonymous';

  script.src = `https://create.lidstatic.com/campaign/${process.env.REACT_APP_JORNAYA_TOKEN}.js?snippet_version=2&callback=setUniversalLeadId`;
  document.body.appendChild(script);
}
