import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import TermsModal from 'components/TermsModal';
import moment from 'moment';
import StyledFooter, { Legal } from './styled';

const FooterElevationNoReviewsVariation = ({
  showLegal,
  theme,
  customText,
  style,
}) => {
  const [showTerm, setShowTerm] = useState(false);

  const openWithNewWindow = (url) => {
    if (!url) {
      return;
    }
    window.open(url, '_new');
  };

  return (
    <>
      {customText && <Legal className="legal-disclosure">{customText}</Legal>}
      <StyledFooter
        className={cls('footer', { 'no-legal': !showLegal && !customText })}
        style={style}
      >
        <div className="privacy">
          Experience by Demand IQ | &copy;{moment().format('YYYY')} | Use is
          subject to&nbsp;
          <span
            onClick={() => {
              // openWithNewWindow('https://www.demand-iq.com/enduserterms')
              setShowTerm(true);
            }}
          >
            Terms
          </span>
          &nbsp;&amp;&nbsp;
          <span onClick={() => openWithNewWindow(theme.privacy_policy)}>
            Privacy Policy
          </span>
        </div>
        {showTerm && <TermsModal onClose={() => setShowTerm(false)} />}
      </StyledFooter>
    </>
  );
};

FooterElevationNoReviewsVariation.propTypes = {
  showLegal: PropTypes.bool,
  customText: PropTypes.any,
  style: PropTypes.object,
};

FooterElevationNoReviewsVariation.defaultProps = {
  showLegal: false,
  customText: '',
  style: {},
};

const mapStateToProps = ({ ui: { theme } }) => ({
  theme,
});

export default connect(mapStateToProps)(FooterElevationNoReviewsVariation);
