import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

// transform snake_case to camelCase
const formattedSuggestion = (structured_formatting) => ({
  mainText: structured_formatting.main_text,
  secondaryText: structured_formatting.secondary_text,
});

export const compose =
  (...fns) =>
  (...args) => {
    fns.forEach((fn) => fn && fn(...args));
  };

class PlacesAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      suggestions: [],
      userInputValue: props.value,
      ready: !props.googleCallbackName,
      first: null,
      inputChild: null,
    };

    this.debouncedFetchPredictions = debounce(
      this.fetchPredictions,
      props.debounce
    );
  }

  componentDidMount() {
    const { googleCallbackName } = this.props;
    if (googleCallbackName) {
      if (!window.google) {
        const key = Object.keys(window).find((e) => e.indexOf('loader') > -1);
        if (key) {
          window[key] = this.googleMapsCallback;
        } else {
          window[googleCallbackName] = this.googleMapsCallback;
        }
      } else {
        this.googleMapsCallback();
      }
    } else {
      this.googleMapsCallback();
    }
  }

  componentDidUpdate() {
    const { ready, inputChild } = this.state;
    const { autoFocus } = this.props;
    if (ready && inputChild && autoFocus) {
      inputChild.focus();
    }
  }

  componentWillUnmount() {
    const { googleCallbackName } = this.props;
    if (googleCallbackName && window[googleCallbackName]) {
      delete window[googleCallbackName];
    }
  }

  googleMapsCallback = () => {
    if (!window.google) {
      throw new Error(
        '[react-places-autocomplete]: Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
      );
    }

    if (!window.google.maps.places) {
      throw new Error(
        '[react-places-autocomplete]: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
      );
    }

    this.autocompleteService =
      new window.google.maps.places.AutocompleteService();
    this.autocompleteOK = window.google.maps.places.PlacesServiceStatus.OK;
    this.setState((state) => {
      if (state.ready) {
        return null;
      }
      return { ready: true };
    });
  };

  autocompleteCallback = (predictions, status) => {
    const { onError } = this.props;
    this.setState({ loading: false });
    if (status !== this.autocompleteOK) {
      onError(status, this.clearSuggestions);
      return;
    }
    const { highlightFirstSuggestion } = this.props;
    const suggestions = predictions.map((p, idx) => ({
      id: p.id,
      description: p.description,
      placeId: p.place_id,
      active: !!(highlightFirstSuggestion && idx === 0),
      index: idx,
      formattedSuggestion: formattedSuggestion(p.structured_formatting),
      matchedSubstrings: p.matched_substrings,
      terms: p.terms,
      types: p.types,
    }));
    this.setState({
      suggestions,
      first: suggestions && suggestions.length > 0 ? suggestions[0] : null,
    });
  };

  fetchPredictions = () => {
    const { value, searchOptions } = this.props;
    if (value.length) {
      this.setState({ loading: true });
      this.autocompleteService.getPlacePredictions(
        {
          ...searchOptions,
          input: value,
        },
        this.autocompleteCallback
      );
    }
  };

  clearSuggestions = (removeFirst = true) => {
    if (removeFirst) {
      this.setState({ first: null });
    }
    this.setState({ suggestions: [] });
  };

  clearActive = () => {
    const { suggestions } = this.state;
    this.setState({
      suggestions: suggestions.map((suggestion) => ({
        ...suggestion,
        active: false,
      })),
    });
  };

  handleSelect = (address, placeId) => {
    this.clearSuggestions();
    const { onSelect, onChange } = this.props;
    if (onSelect) {
      onSelect(address, placeId);
    } else {
      onChange(address);
    }
  };

  getActiveSuggestion = () => {
    const { suggestions } = this.state;
    return suggestions.find((suggestion) => suggestion.active);
  };

  selectActiveAtIndex = (index) => {
    const { onChange } = this.props;
    const { suggestions } = this.state;
    const activeName = suggestions.find(
      (suggestion) => suggestion.index === index
    ).description;
    this.setActiveAtIndex(index);
    onChange(activeName);
  };

  selectUserInputValue = () => {
    const { onChange } = this.props;
    const { userInputValue } = this.state;
    this.clearActive();
    onChange(userInputValue);
  };

  handleEnterKey = () => {
    const { value } = this.props;
    const activeSuggestion = this.getActiveSuggestion();
    if (activeSuggestion === undefined) {
      this.handleSelect(value, null);
    } else {
      this.handleSelect(activeSuggestion.description, activeSuggestion.placeId);
    }
  };

  handleDownKey = () => {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return;
    }

    const activeSuggestion = this.getActiveSuggestion();
    if (activeSuggestion === undefined) {
      this.selectActiveAtIndex(0);
    } else if (activeSuggestion.index === suggestions.length - 1) {
      this.selectUserInputValue();
    } else {
      this.selectActiveAtIndex(activeSuggestion.index + 1);
    }
  };

  handleUpKey = () => {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return;
    }

    const activeSuggestion = this.getActiveSuggestion();
    if (activeSuggestion === undefined) {
      this.selectActiveAtIndex(suggestions.length - 1);
    } else if (activeSuggestion.index === 0) {
      this.selectUserInputValue();
    } else {
      this.selectActiveAtIndex(activeSuggestion.index - 1);
    }
  };

  handleInputKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.handleEnterKey();
        break;
      case 'ArrowDown':
        event.preventDefault(); // prevent the cursor from moving
        this.handleDownKey();
        break;
      case 'ArrowUp':
        event.preventDefault(); // prevent the cursor from moving
        this.handleUpKey();
        break;
      case 'Escape':
        this.clearSuggestions();
        break;
      default:
        break;
    }
  };

  setActiveAtIndex = (index) => {
    const { suggestions } = this.state;
    this.setState({
      suggestions: suggestions.map((suggestion, idx) => {
        if (idx === index) {
          return { ...suggestion, active: true };
        }
        return { ...suggestion, active: false };
      }),
    });
  };

  handleInputChange = (event) => {
    const { onChange, shouldFetchSuggestions } = this.props;
    const { value } = event.target;
    onChange(value);
    this.setState({ userInputValue: value });
    if (!value) {
      this.clearSuggestions();
      return;
    }
    if (shouldFetchSuggestions) {
      this.debouncedFetchPredictions();
    }
  };

  handleInputOnBlur = () => {
    if (!this.mousedownOnSuggestion) {
      this.clearSuggestions(false);
    }
  };

  getActiveSuggestionId = () => {
    const activeSuggestion = this.getActiveSuggestion();
    return activeSuggestion
      ? `PlacesAutocomplete__suggestion-${activeSuggestion.placeId}`
      : undefined;
  };

  getIsExpanded = () => {
    const { suggestions } = this.state;
    return suggestions.length > 0;
  };

  getInputProps = (options = {}) => {
    if (options.value) {
      throw new Error(
        '[react-places-autocomplete]: getInputProps does not accept `value`. Use `value` prop instead'
      );
    }

    if (options.onChange) {
      throw new Error(
        '[react-places-autocomplete]: getInputProps does not accept `onChange`. Use `onChange` prop instead'
      );
    }

    const { ready } = this.state;
    const { value } = this.props;

    const defaultInputProps = {
      type: 'text',
      autoComplete: 'off',
      role: 'combobox',
      'aria-autocomplete': 'list',
      'aria-expanded': this.getIsExpanded(),
      'aria-activedescendant': this.getActiveSuggestionId(),
      disabled: !ready,
      inputRef: (input) => {
        this.state.inputChild = input;
      },
    };

    return {
      ...defaultInputProps,
      ...options,
      onKeyDown: compose(this.handleInputKeyDown, options.onKeyDown),
      onBlur: compose(this.handleInputOnBlur, options.onBlur),
      value,
      onChange: (event) => {
        this.handleInputChange(event);
      },
    };
  };

  getSuggestionItemProps = (suggestion, options = {}) => {
    const handleSuggestionMouseEnter = this.handleSuggestionMouseEnter.bind(
      this,
      suggestion.index
    );
    const handleSuggestionClick = this.handleSuggestionClick.bind(
      this,
      suggestion
    );

    return {
      ...options,
      key: suggestion.id,
      id: this.getActiveSuggestionId(),
      role: 'option',
      onMouseEnter: compose(handleSuggestionMouseEnter, options.onMouseEnter),
      onMouseLeave: compose(
        this.handleSuggestionMouseLeave,
        options.onMouseLeave
      ),
      onMouseDown: compose(this.handleSuggestionMouseDown, options.onMouseDown),
      onMouseUp: compose(this.handleSuggestionMouseUp, options.onMouseUp),
      onTouchStart: compose(
        this.handleSuggestionTouchStart,
        options.onTouchStart
      ),
      onTouchEnd: compose(this.handleSuggestionMouseUp, options.onTouchEnd),
      onClick: compose(handleSuggestionClick, options.onClick),
    };
  };

  handleSuggestionMouseEnter = (index) => {
    this.setActiveAtIndex(index);
  };

  handleSuggestionMouseLeave = () => {
    this.mousedownOnSuggestion = false;
    this.clearActive();
  };

  handleSuggestionMouseDown = (event) => {
    event.preventDefault();
    this.mousedownOnSuggestion = true;
  };

  handleSuggestionTouchStart = () => {
    this.mousedownOnSuggestion = true;
  };

  handleSuggestionMouseUp = () => {
    this.mousedownOnSuggestion = false;
  };

  handleSuggestionClick = (suggestion, event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    const { description, placeId } = suggestion;
    this.handleSelect(description, placeId);
    setTimeout(() => {
      this.mousedownOnSuggestion = false;
    });
  };

  getFirstSuggestion = () => {
    const { first } = this.state;
    return first;
  };

  render() {
    const { children } = this.props;
    const { loading, suggestions } = this.state;
    return children({
      getInputProps: this.getInputProps,
      getSuggestionItemProps: this.getSuggestionItemProps,
      loading,
      suggestions,
      getFirstSuggestion: this.getFirstSuggestion,
    });
  }
}

PlacesAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onSelect: PropTypes.func,
  searchOptions: PropTypes.shape({
    bounds: PropTypes.object,
    componentRestrictions: PropTypes.object,
    location: PropTypes.object,
    offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    types: PropTypes.array,
  }),
  debounce: PropTypes.number,
  highlightFirstSuggestion: PropTypes.bool,
  shouldFetchSuggestions: PropTypes.bool,
  googleCallbackName: PropTypes.string,
};

PlacesAutocomplete.defaultProps = {
  /* eslint-disable no-unused-vars, no-console */
  onError: (status, _clearSuggestions) =>
    console.error(
      '[react-places-autocomplete]: error happened when fetching data from Google Maps API.\nPlease check the docs here (https://developers.google.com/maps/documentation/javascript/places#place_details_responses)\nStatus: ',
      status
    ),
  /* eslint-enable no-unused-vars, no-console */
  searchOptions: {},
  debounce: 200,
  highlightFirstSuggestion: false,
  shouldFetchSuggestions: true,
};

export default PlacesAutocomplete;
