/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button';
import Divider from 'components/Divider';
import MaterialStepper from 'components/MaterialStepper';
import { ReactComponent as CameraIcon } from 'assets/images/icon-camera.svg';
import {
  setUserData,
  sendUtilityBillEmail,
  saveSession,
  navigateTo,
  trackForwardProgress,
  trackGoogleLead,
} from 'store/actions';
import { Link } from '@material-ui/core';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import StyledBills1 from './styled';

class Bills1D2DVariation extends Component {
  constructor(props) {
    super(props);

    props.actions.trackGoogleLead('bills1', props.user.variation);
  }

  handleNext = async () => {
    const { history, actions } = this.props;
    actions.trackForwardProgress();
    await history.push('/bills2');
  };

  handleSkip = async () => {
    const { actions } = this.props;
    actions.setUserData({
      step: '/start',
      skippedBills: true,
    });
    await actions.sendUtilityBillEmail();
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/thankyou', false);
  };

  handleUpload = async () => {
    const { history, actions } = this.props;
    actions.trackForwardProgress();
    history.push('/upload-bills');
  };

  render() {
    const { t, user } = this.props;

    return (
      <DefaultLayout>
        <StyledBills1 className="content bills-1-page custom-stepper">
          <div className="form">
            <div className="material-progress">
              <MaterialStepper activeStep={2} variation={user.variation} />
            </div>
            <div className="form-content text-center flex-wrapper">
              <div className="form-title mb-8">
                {t('Appointment Confirmed')}
              </div>
              <div className="form-subtitle mb-16">
                {t('Now the Last Step!')}
              </div>
              <div className="form-subtext mb-24">
                {t(
                  'To accurately size your custom solar panel system, we will need copies of your last electric bills.'
                )}
              </div>
              <Button full className="camera-btn" onClick={this.handleUpload}>
                <CameraIcon />
                {t('Upload your Bills')}
              </Button>
              <div className="m-24">
                <Divider text="OR" />
              </div>
              <Button outline full onClick={this.handleNext}>
                {t('Opt in to share utility data')}
              </Button>
              <div className="link-container">
                <Link
                  href="#"
                  onClick={() => this.handleSkip()}
                  underline="always"
                  variant="body1"
                >
                  {t('Skip Step')}
                </Link>
              </div>
            </div>
          </div>
        </StyledBills1>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data } }) => ({
  user: data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      sendUtilityBillEmail,
      saveSession,
      navigateTo,
      trackForwardProgress,
      trackGoogleLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Bills1D2DVariation)
);
