import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import Button from 'components/Button';
import { ReactComponent as ThankyouIcon } from 'assets/images/icon-thankyou.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackGoogleLead,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { getPreviewPageName } from 'common/api.mock';
import StyledThankyou from './styled';

class ThankyouCFSVariation extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props;
    if (user.skippedBills) return;
    props.actions.trackGoogleLead('thankyou', props.user.variation);
  }

  handleNext = () => {
    const previewPage = getPreviewPageName();
    if (previewPage === 'thankyou') return;
    const {
      theme: { testimonials_url, spanish_testimonials_url },
    } = this.props;
    const language = getLocalStorageItem('i18nextLng');
    const url = /^es\b/.test(language)
      ? spanish_testimonials_url
      : testimonials_url;
    if (!url) {
      return;
    }
    window.open(url, '_top');
  };

  handleRestart = async () => {
    const { actions } = this.props;
    await this.handleClose();
    actions.setUserData({
      back_step: '/',
      step: '/start',
    });
    await actions.saveSession();
    await actions.navigateTo('/start');
  };

  render() {
    const { user, t, theme } = this.props;
    const previewPage = getPreviewPageName();
    const language = getLocalStorageItem('i18nextLng');
    const statement = /^es\b/.test(language)
      ? theme.spanish_thanks_statement
      : theme.thanks_statement;

    return (
      <DefaultLayout showRestartButton={false}>
        <StyledThankyou className="content thankyou-page">
          <div className="form">
            <div className="form-content text-center">
              <div className="mb-32">
                <ThankyouIcon />
              </div>

              <div className="form-title mb-16">{t("We'll be in touch!")}</div>

              <div className="form-subtext mb-32 px-8">{statement}</div>

              <div className="form-subtext meeting-text mb-16">
                {t('We look forward to speaking with you on')}
              </div>

              <div className="form-subtext meeting-text mb-32 bold black">
                <span style={{ textTransform: 'capitalize' }}>
                  {previewPage === 'thankyou'
                    ? moment().format('dddd, MMMM D')
                    : moment(user.meeting_date, 'YYYY-MM-DD').format(
                        'dddd, MMMM D'
                      )}
                </span>
                <span>{` ${t('at')} `}</span>
                <span>
                  {previewPage === 'thankyou'
                    ? moment().format('h:mm A')
                    : moment(user.meeting_time, 'HH:mm:ss').format('h:mm A')}
                </span>
              </div>

              <Button full outline onClick={this.handleNext}>
                {t('BACK TO OUR HOMEPAGE')}
              </Button>
            </div>
          </div>
        </StyledThankyou>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.ui.theme,
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackGoogleLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(ThankyouCFSVariation)
);
