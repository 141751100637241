import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Header from 'components/Header';
import MaterialStepper from 'components/MaterialStepper';
import Button from 'components/Button';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import { mainTimezones } from 'common/data';
import { withTranslation } from 'react-i18next';
import {
  setUserData,
  saveSession,
  navigateTo,
  getEventInfo,
  trackForwardProgress,
  trackFacebookPixel,
} from 'store/actions';
import { sessionAPIInstance } from 'common/api.mock';
import StyledMeetingCalendly from './styled';
import Loading from '../../../components/Loading';

class MeetingCalendlyNoUtilityBillVariation extends Component {
  constructor(props) {
    super(props);

    const timezones = mainTimezones.map((t) => ({
      ...t,
      offset: moment.tz.zone(t.value).utcOffset(new Date()),
    }));

    const offset = moment.tz.zone(moment.tz.guess(true)).utcOffset(new Date());

    let timezone = timezones.filter((t) => t.offset === offset)[0];
    if (!timezone) {
      // eslint-disable-next-line prefer-destructuring
      timezone = timezones[3];
    }
    timezone = timezone.value;

    const { user, theme } = props;

    const { custom_mapping } = theme;

    const customAnswers = {};
    custom_mapping.forEach((c) => {
      if (c.custom_mapper_type === 'question') {
        const index = (user.answers || []).findIndex(
          (e) => e.question?.id === c.custom_question
        );
        if (index !== -1) {
          customAnswers[`a${c.order + 1}`] = user.answers[index].choice?.option;
        }
      } else if (c.custom_mapper_type === 'field') {
        customAnswers[`a${c.order + 1}`] = user[c.custom_question];
      }
    });

    this.state = {
      timezone: props.user.meeting_timezone || timezone,
      meeting_time: props.user.meeting_time,
      meeting_date: props.user.meeting_date,
      widgetLoading: true,
      is_scheduled_meeting: false,
      prefill_data: {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        name: `${user.first_name} ${user.last_name}`,
        customAnswers,
      },
      events: null,
      eventUrl: null,
    };
  }

  componentDidMount() {
    const loadCalendars = async () => {
      const res = await sessionAPIInstance.post(
        '/api/progresses/calendly-events/'
      );
      if (res.status === 200) {
        this.setState({
          events: res.data.collection,
        });
      } else {
        this.setState({
          events: [],
        });
      }
    };

    loadCalendars();
  }

  handleNext = async () => {
    const { actions, user } = this.props;
    const { timezone, meeting_time, meeting_date } = this.state;
    if (!meeting_time || !meeting_date) {
      return;
    }

    const step = 'thankyou';

    actions.trackFacebookPixel('thankyou', user.variation);

    actions.setUserData({
      meeting_date,
      meeting_time,
      meeting_timezone: timezone,
      is_confirmed: true,
      step,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  filterDate = (date) => {
    const { availableDays } = this.state;
    return availableDays[moment(date).day()];
  };

  onEventTypeViewed = () => {
    this.setState({
      widgetLoading: false,
    });
  };

  onDateAndTimeSelected = () => {
    this.setState({
      meeting_time: null,
      meeting_date: null,
    });
  };

  onEventScheduled = async (event) => {
    // console.log(data);
    const { data } = event;
    const { payload } = data;
    const { actions, user } = this.props;

    this.setState({
      is_scheduled_meeting: true,
    });

    await actions.getEventInfo(user.solar_company, payload.event.uri);
    const { calendly } = this.props;

    const timezones = mainTimezones.map((t) => ({
      ...t,
      offset: moment.tz
        .zone(t.value)
        .utcOffset(new Date(calendly.meeting_time)),
    }));

    const offset = moment.tz
      .zone(moment.tz.guess(true))
      .utcOffset(new Date(calendly.meeting_time));

    let timezone = timezones.filter((t) => t.offset === offset)[0];
    if (!timezone) {
      // eslint-disable-next-line prefer-destructuring
      timezone = timezones[3];
    }
    timezone = timezone.value;
    const start_time = moment(calendly.meeting_time).tz(timezone);
    const meeting_date = start_time.format('YYYY-MM-DD');
    const meeting_time = start_time.format('HH:mm:ss');

    this.setState({
      timezone,
      meeting_time,
      meeting_date,
    });
  };

  handleEventSelect = (eventUrl) => {
    this.setState({
      eventUrl,
    });
  };

  handleEventDeselect = () => {
    this.setState({
      eventUrl: null,
    });
  };

  render() {
    const {
      meeting_time,
      meeting_date,
      widgetLoading,
      is_scheduled_meeting,
      prefill_data,
      events,
      eventUrl,
    } = this.state;
    const { saving, t, user } = this.props;

    const myEvents = events
      ? events.filter((d) => d.pooling_type === null)
      : [];
    const sharedEvents = events
      ? events.filter((d) => d.pooling_type !== null)
      : [];

    return (
      <>
        <Header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledMeetingCalendly className="content meeting-1-page custom-stepper">
              <div className="form" id="form-meeting1">
                <div className="material-progress">
                  <MaterialStepper activeStep={2} variation={user.variation} />
                </div>
                <div className="form-content text-center">
                  {eventUrl ? (
                    <>
                      {widgetLoading ? (
                        <Loading />
                      ) : (
                        <div
                          className="back-event-list"
                          onClick={this.handleEventDeselect}
                        >
                          Back to event list
                        </div>
                      )}
                      <div className="form-datepicker mb-16" id="pickerWrapper">
                        <CalendlyEventListener
                          onEventScheduled={this.onEventScheduled}
                          onDateAndTimeSelected={this.onDateAndTimeSelected}
                          onEventTypeViewed={this.onEventTypeViewed}
                          onProfilePageViewed={this.onEventTypeViewed}
                        >
                          <InlineWidget
                            // url={theme.calendly_event_link}
                            url={eventUrl}
                            prefill={prefill_data}
                            styles={{
                              flex: 1,
                            }}
                          />
                        </CalendlyEventListener>
                      </div>
                    </>
                  ) : (
                    <div className="event-list">
                      {events ? (
                        <div>
                          {myEvents.length > 0 && (
                            <div className="event-group">
                              <div className="event-group-title">
                                Single Events
                              </div>
                              {myEvents.map((d) => (
                                <div
                                  className="event-item"
                                  key={d.scheduling_url}
                                  onClick={() =>
                                    this.handleEventSelect(d.scheduling_url)
                                  }
                                >
                                  {d.name}
                                </div>
                              ))}
                            </div>
                          )}
                          {sharedEvents.length > 0 && (
                            <div className="event-group">
                              <div className="event-group-title">
                                Shared Events
                              </div>
                              {sharedEvents.map((d) => (
                                <div
                                  className="event-item"
                                  key={d.scheduling_url}
                                  onClick={() =>
                                    this.handleEventSelect(d.scheduling_url)
                                  }
                                >
                                  {d.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        <Loading />
                      )}
                    </div>
                  )}
                </div>
                {is_scheduled_meeting && (
                  <div className="form-actions form-footer">
                    <Button
                      onClick={this.handleNext}
                      full
                      disabled={!meeting_time || !meeting_date || saving}
                    >
                      {t('Continue')}
                      <ArrowRightIcon />
                    </Button>
                  </div>
                )}
              </div>
            </StyledMeetingCalendly>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  user: { data, saving, calendly },
  ui: { theme },
}) => ({
  user: data,
  saving,
  theme,
  calendly,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      getEventInfo,
      navigateTo,
      trackForwardProgress,
      trackFacebookPixel,
    },
    dispatch
  ),
});

export default withTranslation('tarnslation')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MeetingCalendlyNoUtilityBillVariation)
);
