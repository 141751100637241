import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import MaterialStepper from 'components/MaterialStepper';
import {
  getAvailableDays,
  getTheNearestAvailableDate,
  getTheAvailableTimeFrames,
  roundDate,
  getDefaultTimezone,
} from 'common/utils';
import { BUFFER_TIME, GAP_TIME } from 'common/constants';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import Loading from 'components/Loading';
import _ from 'lodash';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
  appointmentTimes,
  availableCalendarTimes,
} from '../../../store/actions';
import StyledMeeting1 from './styled';

class Meeting1NoUtilityBillVariation extends Component {
  constructor(props) {
    super(props);

    const { actions, theme, user } = props;

    const variation = getLocalStorageItem('variation');

    const defaultTimezone = getDefaultTimezone();

    const timezone =
      user.meeting_timezone ||
      (!theme.appointment_timezone ||
      theme.appointment_timezone === 'custom_homeowner_timezone'
        ? defaultTimezone
        : theme.appointment_timezone) ||
      defaultTimezone;

    const minDate = getTheNearestAvailableDate(
      theme.appointment_schedule,
      roundDate(
        moment().add(theme.appointment_time_buffer || BUFFER_TIME, 'hours')
      ),
      timezone
    ).format();
    const browserTz = moment.tz.guess(true);

    let gapTime = theme.appointment_gap_time;
    if (
      theme.appointment_gap_time === null ||
      theme.appointment_gap_time === undefined
    )
      gapTime = GAP_TIME;

    const startAt = moment.tz(minDate, 'YYYY-MM-DD', browserTz);
    const endAt = moment(startAt).add(
      (theme.appointment_time_buffer || BUFFER_TIME) >= 7 * 24 ? 21 : 14,
      'days'
    );

    if (theme.is_duplication_meeting_prevented) {
      actions.appointmentTimes({
        start_at: startAt.format('YYYY-MM-DD'),
        end_at: endAt.format('YYYY-MM-DD'),
      });

      if (theme.is_cronofy_connected && theme.is_calendar_active) {
        actions.availableCalendarTimes({
          start_at: startAt.format('YYYY-MM-DD'),
          end_at: endAt.format('YYYY-MM-DD'),
          timezone,
          meeting_buffer_time: gapTime,
        });
      }
    }

    this.state = {
      date: null,
      availableDays: getAvailableDays(theme.appointment_schedule, timezone),
      minDate: startAt,
      maxDate: endAt,
      variation: VARIATION_OPTIONS[variation],
      availableDates: null,
      timezone,
    };
  }

  componentDidMount() {
    const { theme } = this.props;
    if (!theme.is_duplication_meeting_prevented) {
      this.getAvailableDates();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      is_calendar_available_slots_loaded,
      is_appointments_loaded,
      theme,
    } = this.props;

    if (theme.is_duplication_meeting_prevented) {
      if (theme.is_cronofy_connected && theme.is_calendar_active) {
        if (!is_appointments_loaded || !is_calendar_available_slots_loaded) {
          return;
        }
      }
      if (!is_appointments_loaded) return;
    }

    if (
      (is_appointments_loaded !== prevProps.is_appointments_loaded ||
        is_calendar_available_slots_loaded !==
          prevProps.is_calendar_available_slots_loaded) &&
      theme.is_duplication_meeting_prevented
    ) {
      this.getAvailableDates();
    }
  }

  handleNext = async () => {
    const { actions } = this.props;
    const { date } = this.state;
    actions.setUserData({
      meeting_date: moment(date).format('YYYY-MM-DD'),
      step: '/meeting2',
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/meeting2');
  };

  getAvailableDates = () => {
    const dates = [];
    const { timezone } = this.state;
    const { theme, availableTimes, appointments } = this.props;
    const { maxDate, minDate } = this.state;

    const max = _.cloneDeep(maxDate);
    const min = _.cloneDeep(minDate);

    let gapTime = theme.appointment_gap_time;
    if (
      theme.appointment_gap_time === null ||
      theme.appointment_gap_time === undefined
    )
      gapTime = GAP_TIME;

    const tz =
      !theme.appointment_timezone ||
      theme.appointment_timezone === 'custom_homeowner_timezone'
        ? 'UTC'
        : timezone;

    for (let m = min; m.diff(max, 'days') <= 0; m.add(1, 'days')) {
      const curTime = roundDate(
        moment().add(theme.appointment_time_buffer || BUFFER_TIME, 'hours')
      );
      const frames = getTheAvailableTimeFrames(
        theme.appointment_schedule,
        {
          start: moment.max(moment.tz(m, tz).startOf('day'), curTime),
          end: moment.tz(m, tz).endOf('day'),
        },
        tz
      );

      let isAvailableDate = false;
      frames.some((frame) => {
        const start = moment.tz(frame.start, 'YYYY-MM-DDTHH:mm:ss', timezone);
        while (
          start.isBefore(moment.tz(frame.end, 'YYYY-MM-DDTHH:mm:ss', timezone))
        ) {
          let booked = false;
          let isAvailable = false;

          if (theme.is_duplication_meeting_prevented) {
            appointments.some((time) => {
              if (time) {
                const appointmentStart = moment(time)
                  .subtract(gapTime, 'minutes')
                  .tz(timezone);
                const appointmentEnd = moment(time)
                  .add(1, 'hours')
                  .add(gapTime, 'minutes')
                  .tz(timezone);
                const startTime = start.clone();
                if (
                  (startTime.isSameOrAfter(appointmentStart) &&
                    startTime.isBefore(appointmentEnd)) ||
                  (appointmentStart.isSameOrAfter(startTime) &&
                    appointmentStart.isBefore(startTime.add(1, 'hours')))
                ) {
                  booked = true;
                  return true;
                }
              }
              return false;
            });
          }

          if (
            theme.is_duplication_meeting_prevented &&
            theme.is_cronofy_connected &&
            theme.is_calendar_active
          ) {
            availableTimes.some((time) => {
              const appointmentStart = moment(time.start).tz(timezone);
              const appointmentEnd = moment(time.end).tz(timezone);
              const startTime = start.clone();
              if (
                startTime.isSameOrAfter(appointmentStart) &&
                startTime.isSameOrBefore(appointmentEnd)
              ) {
                isAvailable = true;
                return true;
              }
              return false;
            });
          } else {
            isAvailable = true;
          }

          if (!booked && isAvailable) {
            isAvailableDate = true;
            break;
          }
          start.add('30', 'minutes');
        }
        return isAvailableDate;
      });

      if (isAvailableDate) {
        dates.push(_.cloneDeep(moment.tz(m, timezone).format('YYYY-MM-DD')));
      }
    }

    this.setState({
      availableDates: dates,
    });
  };

  checkDateAvailable = (date) => {
    const { availableDates } = this.state;

    if (_.isNull(availableDates)) {
      return false;
    }

    let isAvailable = false;
    if (availableDates && availableDates.length) {
      availableDates.some((d) => {
        if (d === moment(date).format('YYYY-MM-DD')) {
          isAvailable = true;
          return true;
        }
        return false;
      });
      return isAvailable;
    }
    return false;
  };

  filterDate = (date) => {
    const { availableDays } = this.state;
    return availableDays[moment(date).day()] && this.checkDateAvailable(date);
  };

  handleDateChange = async (value) => {
    this.setState({ date: value });

    const { actions } = this.props;
    actions.setUserData({
      meeting_date: moment(value).format('YYYY-MM-DD'),
      step: '/meeting2',
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/meeting2');
  };

  render() {
    const { date, minDate, maxDate, variation } = this.state;
    const { loading, t, user } = this.props;

    return (
      <>
        <variation.header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledMeeting1 className="content meeting-1-page custom-stepper">
              <div className="form" id="form-meeting1">
                <div className="material-progress">
                  <MaterialStepper activeStep={2} variation={user.variation} />
                </div>
                <div className="form-content text-center">
                  <div className="form-title mb-16">
                    {t('What day works best?')}
                  </div>
                  <div className="form-subtext mb-16">
                    {t(
                      'Pick a day to review your personalized quote over the phone.'
                    )}
                  </div>
                  {!loading ? (
                    <div className="form-datepicker mb-16">
                      <DatePicker
                        onChange={this.handleDateChange}
                        value={date}
                        filterDate={this.filterDate}
                        minDate={minDate.toDate()}
                        maxDate={maxDate.toDate()}
                      />
                    </div>
                  ) : (
                    <div className="mb-32">
                      <Loading />
                    </div>
                  )}
                </div>
              </div>
            </StyledMeeting1>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  user: { data, saving },
  ui: { theme },
  appointment: {
    appointments,
    loading,
    availableTimes,
    is_calendar_available_slots_loaded,
    is_appointments_loaded,
  },
}) => ({
  user: data,
  saving,
  theme,
  appointments,
  availableTimes,
  loading,
  is_calendar_available_slots_loaded,
  is_appointments_loaded,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
      appointmentTimes,
      availableCalendarTimes,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Meeting1NoUtilityBillVariation)
);
