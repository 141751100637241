import ReactPixel from 'react-facebook-pixel';
import history from 'common/history';
import * as demo from 'common/data';
import * as APIMock from 'common/api.mock';
import { checkIfImageIsLoaded, getCookie } from 'common/utils';
import __ from 'lodash';
import { VARIATION_OPTIONS } from 'pages/constants';
import { v4 as uuidv4 } from 'uuid';
import { getLocalStorageItem, setLocalStorageItem } from 'common/localStorage';

/**
 * Action Types
 */
export const LOAD_THEME_REQUEST = 'LOAD_THEME_REQUEST';
export const LOAD_THEME_SUCCESS = 'LOAD_THEME_SUCCESS';
export const LOAD_THEME_FAILURE = 'LOAD_THEME_FAILURE';
export const LOAD_VARIATIONS_REQUEST = 'LOAD_VARIATIONS_REQUEST';
export const LOAD_VARIATIONS_SUCCESS = 'LOAD_VARIATIONS_SUCCESS';
export const LOAD_VARIATIONS_FAILURE = 'LOAD_VARIATIONS_FAILURE';
export const PREVIEW_THEME = 'PREVIEW_THEME';

/**
 * Action Creators
 */
export const loadThemeSuccess = (theme) => ({
  type: LOAD_THEME_SUCCESS,
  theme,
});

export const previewTheme = (theme) => ({
  type: PREVIEW_THEME,
  theme,
});

export const loadSolarCompany = () => {
  return async (dispatch) => {
    let data;
    dispatch({
      type: LOAD_THEME_REQUEST,
    });
    try {
      const result = await APIMock.loadSolarCompany();
      data = {};
      Object.keys(result).forEach((key) => {
        data[key] = __.isNil(result[key])
          ? demo.defaultTheme[key]
          : result[key];
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: LOAD_THEME_FAILURE,
      });
    }
    if (!data) {
      data = demo.defaultTheme;
    }
    data.legal = demo.defaultTheme.legal.replace(
      /\[SOLAR_COMPANY_NAME\]/g,
      data.company_name || 'Stella'
    );
    data.disclosure = demo.defaultTheme.disclosure
      .replace('[APR]', data.product_apr)
      .replace('[TERM]', data.product_term);

    // calculate Qualification questions count (default questions count: 2)
    data.questions_count = Array.isArray(data.chosen_questions)
      ? data.chosen_questions.length + 2
      : 2;

    // Attach custom properties to heap user profiles(https://docs.heap.io/reference#adduserproperties).
    if (process.env.REACT_APP_TRACKING_ENV === 'production' && window.heap) {
      window.heap.addUserProperties({ 'Solar Client': data.company_name });
    }

    const images = [
      data.logo,
      data.background,
      data.ai_avatar,
      data.spanish_ai_avatar,
      data.hero_image_desktop,
      data.hero_image_mobile,
    ];
    await checkIfImageIsLoaded(images);
    dispatch(loadThemeSuccess(data));
  };
};

export const trackFacebookPixel = (step, variation, data = null) => {
  const { facebookPixelStatus } = VARIATION_OPTIONS[variation || 'current'];
  return (_, getState) => {
    const {
      ui: { theme },
      user,
    } = getState();
    if (!theme.facebook_pixel_id) {
      return;
    }
    // eslint-disable-next-line array-callback-return
    facebookPixelStatus.forEach(async (item) => {
      if (item.step !== step) return;

      // get progress track status
      const fb_status = user.data.fb_track_status;

      if (!fb_status[`is_${item.status}`]) {
        // generate event id
        const eventID = getLocalStorageItem('eventID') || uuidv4();
        if (!getLocalStorageItem('eventID')) {
          setLocalStorageItem('eventID', eventID);
        }
        ReactPixel.fbq('track', item.status, data, { eventID });

        // Get fbp value from cookie
        const fbp = getCookie('_fbp') || getCookie('fbp');
        // send request to server to track fb
        const param = {
          event_id: eventID,
          event_name: item.status,
          fbp,
        };
        await APIMock.trackFacebookPixelFromServer(param);
      }
    });
  };
};

export const trackGoogleLead = (step, variation) => {
  const { googleLeadStatus } = VARIATION_OPTIONS[variation || 'current'];
  return (_, getState) => {
    const {
      ui: { theme },
      user,
    } = getState();
    // eslint-disable-next-line array-callback-return
    googleLeadStatus.forEach((item) => {
      if (item.step !== step) return;
      window.ga('stellaTracker.send', {
        hitType: 'event',
        eventCategory: 'Lead Status',
        eventAction: 'Change Status',
        eventLabel: item.status,
        sessionId: user.data.session_id,
      });
      if (theme.google_analytics_site_tag) {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'Lead Status',
          eventAction: 'Change Status',
          eventLabel: item.status,
          sessionId: user.data.session_id,
        });
      }

      if (theme.google_ads_code_lead && item.status === 'Lead') {
        window.gtag('event', 'conversion', {
          session_id: user.data.session_id,
          send_to: `${theme.google_ads_global_site_tag}/${theme.google_ads_code_lead}`,
        });
      }

      if (theme.google_ads_code_apt && item.status === 'Appointment') {
        window.gtag('event', 'conversion', {
          session_id: user.data.session_id,
          send_to: `${theme.google_ads_global_site_tag}/${theme.google_ads_code_apt}`,
        });
      }

      if (
        theme.google_ads_code_bill &&
        item.status === 'Appointment with Bill'
      ) {
        window.gtag('event', 'conversion', {
          session_id: user.data.session_id,
          send_to: `${theme.google_ads_global_site_tag}/${theme.google_ads_code_bill}`,
        });
      }
    });
  };
};

export const navigateTo = (path, needUrjanetChecking = true) => {
  return (_, getState) => {
    const {
      user: { data: user },
    } = getState();

    if (!needUrjanetChecking) {
      history.replace(
        path || (user.is_instant_estimate ? '/result3' : '/start')
      );
      return;
    }

    if (
      user &&
      user.urjanet_status === demo.urjanetStatuses.failed &&
      window.location.pathname.indexOf('/bills') === -1
    ) {
      history.replace('/bills1');
      return;
    }
    history.replace(path);
  };
};

export const loadVariationsSuccess = (variations) => ({
  type: LOAD_VARIATIONS_SUCCESS,
  variations,
});

export const loadVariations = () => {
  return async (dispatch) => {
    let data;
    dispatch({
      type: LOAD_VARIATIONS_REQUEST,
    });
    try {
      const result = await APIMock.loadVariations();
      data = result;
    } catch (error) {
      console.error(error);
      dispatch({
        type: LOAD_VARIATIONS_FAILURE,
      });
    }
    dispatch(loadVariationsSuccess(data));
  };
};
