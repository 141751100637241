import React from 'react';
import Slider from 'react-slick';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { PrevArrow, NextArrow, StyledSlider } from './styled';

const SlickSlider = ({
  dots = false,
  slidesToShow = 1,
  showArrows = true,
  variableWidth = true,
  sliderRef = (slider) => slider,
  children,
}) => {
  const settings = {
    dots,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth,
    rows: 1,
    prevArrow: showArrows ? (
      <PrevArrow>
        <BackIcon />
      </PrevArrow>
    ) : null,
    nextArrow: showArrows ? (
      <NextArrow>
        <BackIcon />
      </NextArrow>
    ) : null,
    arrows: showArrows,
  };

  return (
    <StyledSlider>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
    </StyledSlider>
  );
};

export default SlickSlider;
